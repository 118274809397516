import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SliderComponent} from './Slider/slider.component';
import {AvatarComponent} from './avatar/avatar.component';
import { VisitDetailsComponent } from './visit-details/visit-details.component';
import { TableModule } from 'primeng/table';
import { EsocUploadComponent } from './esoc-upload/esoc-upload.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { InputMaskModule } from 'primeng/inputmask';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { LoaderWrapperModule } from '../loader-wrapper/loader-wrapper.module';
import {CustomDatePipe} from "../common/pipes/custom-date.pipe";
import {DateElapsedTimePipe} from "../common/pipes/date-elapsed-time.pipe";
import {noOfDaysPipe} from "../common/pipes/noOf-days.pipe";
import {TruncatePipe} from "../common/pipes/truncate.pipe";
import { DateMaskDirective } from './date-mask.directive';
import { ReplaceNegativeOnePipe } from '../common/pipes/replace-negative-one.pipe';
import {ReplaceStringInHtmlPipe} from "../common/pipes/replace-sting-in-html.pipe";



@NgModule({
  declarations: [
    SliderComponent,
    AvatarComponent,
    VisitDetailsComponent,
    EsocUploadComponent,
    CustomDatePipe,
    DateElapsedTimePipe,
    noOfDaysPipe,
    TruncatePipe,
    DateMaskDirective,
    ReplaceNegativeOnePipe,
    ReplaceStringInHtmlPipe
  ],
  imports: [
    CommonModule,
    TableModule,
    FormsModule,
    DropdownModule,
    CommonModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    SidebarModule,
    MultiSelectModule,
    SliderModule,
    FileUploadModule,
    CalendarModule,
    CheckboxModule,
    ConfirmDialogModule,
    LoaderWrapperModule,
    SelectButtonModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatIconModule,
    InputMaskModule,
    TooltipModule,
  ],
    exports: [
        AvatarComponent,
        VisitDetailsComponent,
        EsocUploadComponent,
        CustomDatePipe,
        DateElapsedTimePipe,
        noOfDaysPipe,
        TruncatePipe,
        DateMaskDirective
    ]
})
export class SharedModule { }
