import { Injectable } from '@angular/core';
import {ServiceClientService} from "src/app/services/service-client.service";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {Agency, AgencyExport, AgencyReportData} from "src/app/models/agency";
import {AgencyRole, Response} from "../../models/user";
import {HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AgencyService {
  constructor(private serviceClientService: ServiceClientService) { }

  // Get all agencies
  getAgencies(params?: HttpParams): Observable<Response<Agency[]>> {
    return this.serviceClientService.get(environment.endPoints.getAgencies, params);
  }

  // find agencies with pagination and search
  getAgenciesWithSearch(request: any): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.getAgenciesWithSearch, request);
  }

  checkIfeDocEnabled(request: any): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.checkIsEdocEnabled, request);
  }

  createAgency(request: Agency): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.createAgency, request);
  }

  getRoleBasedAgencyListing(role: string, agency_roles: AgencyRole[]): Observable<Response<Agency[]>> {
    let agencies: AgencyRole[] = agency_roles.filter(agency => agency.roles.includes(role));
    return this.serviceClientService.post(environment.endPoints.getAgenciesByRole, agencies);
  }
  uploadFile(file): Observable<any> {
    return this.serviceClientService.postImage(environment.endPoints.uploadFile, file);
  }

  deleteFile(file): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.deleteFile, file);
  }

  findAgencyByEmail(request: any): Observable<Response<any>> {
    return this.serviceClientService.post(environment.endPoints.findAgencyByEmail, request);
  }

  deleteAgency(agnecyId)
  {
    return this.serviceClientService.delete(environment.endPoints.deleteAgency + agnecyId)
  }

  updateAgency(agencyToUpdate: Agency)
  {
    return this.serviceClientService.post(environment.endPoints.updateAgency, agencyToUpdate)
  }

  getAgencyErrorsAndWarningsDataToExport(payload): Observable<AgencyExport>{
    return this.serviceClientService.post(environment.endPoints.getAgencyErrorsAndWarningsExportData, payload)
  }

  getUserStatusDataToExport(payload): Observable<AgencyExport>{
    return this.serviceClientService.post(environment.endPoints.getUserStatusExportData, payload)
  }

  getAgencyUsersAndDevicesDataToExport(payload): Observable<any>{
    return this.serviceClientService.post(environment.endPoints.generateIOHUserReport, payload)
  }

  getClinicianChangeReportData(payload): Observable<any>{
    return this.serviceClientService.post(environment.endPoints.clinicianChangeReport, payload)
  }

  getClinicianSuggestionCalculateReportData(payload): Observable<any>{
    return this.serviceClientService.post(environment.endPoints.clinicianSuggestionCalReport, payload)
  }

  getBulkUploadUsersTemplate(): Observable<any>{
    return this.serviceClientService.post(environment.endPoints.getBulkUploadUserTemplate,'')
  }

  getUserActivationReport(payload): Observable<any>{
    return this.serviceClientService.post(environment.endPoints.userActivationReport, payload)
  }

}
