import {ComponentRef, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { CompleteNewPasswordComponent } from '../complete-new-password/complete-new-password.component';
import { MultiSliderComponent } from '../multi-slider/multi-slider.component';
import { CarouselModule } from 'primeng/carousel';
import {CheckboxModule} from 'primeng/checkbox';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import {FooterModule} from "../core/footer/footer.module";
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CreateNewPasswordComponent } from './forgot-password/create-new-password/create-new-password.component';
import { PasswordModule } from 'primeng/password';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { LoaderWrapperModule } from '../loader-wrapper/loader-wrapper.module';
import { PasswordResetDoneComponent } from './forgot-password/password-reset-done/password-reset-done.component';
import { InputTextModule } from 'primeng/inputtext';
import {VerificationComponent} from "../verification/verification.component";
import { ForcePasswordChangeComponent } from './forgot-password/force-password-change/force-password-change.component';
import { SsoAuthorizeModule } from './forgot-password/sso-authorize/sso-authorize.module';


@NgModule({
  declarations: [
    LoginComponent,
    CompleteNewPasswordComponent,
    MultiSliderComponent,
    ForgotPasswordComponent,
    CreateNewPasswordComponent,
    PasswordResetDoneComponent,
    VerificationComponent,
    ForcePasswordChangeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LoginRoutingModule,
    CarouselModule,
    CheckboxModule,
    SharedModule,
    ReactiveFormsModule,
    FooterModule,
    PasswordModule,
    ToggleButtonModule,
    LoaderWrapperModule,
    InputTextModule,
    SsoAuthorizeModule
  ]
})
export class LoginModule { }
