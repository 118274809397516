import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AgencyService } from "src/app/services/agency/agency.service";
import { CompanyService } from "../services/company/company.service";
import {
  Agency,
  AgencyFiltersOptions,
  agencyLevelValues,
  agencyStatusValues,
  status,
  statusValues,
  DiagnosisGroupOptions,
  AgencyReportData,
  AgencyExport,
  AgencyListingRows,
  defaultSelectedAgencyRows,
} from "src/app/models/agency";
import { MessageHandlerService } from "src/app/services/message-handler.service";
import { UserDataManager } from "src/app/user/UserDataManager";
import { User, UserStatus } from "src/app/models/user";
import { Table } from "primeng/table";
import { ConfirmationService, PrimeNGConfig, SelectItem } from "primeng/api";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { countries } from "../models/country-data";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { FileUpload } from "primeng/fileupload";
import {
  USER_DEVICES_REPORT_FILE_NAME,
  ERROR_WARNING_REPORT_FILE_NAME,
  DATE_PICKER_FORMAT,
  DATE_PICKER_PLACEHOLDER,
  DEFAULT_SEARCH_MIN_CHARACTER,
  EXCEL_EXTENSION,
  EXCEL_TYPE,
  RoleID,
  dateFormatStartWithYear,
  dateFormatStartWithYearWithOutDashes,
  portalFeatureValues,
  rowsPerPageOptions,
  CURRENT_RELEASE_VERSION,
  RELOAD_PAGE_AFTER_RELEASE,
  EMAIL_ALREADY_REGISTERED_AGENCY,
  QUESTIONAIR_TYPE_ERROR,
  QUESTIONAIR_TYPE_WARN,
  QUESTIONAIR_TYPE_WARNING,
  IMPACT,
  ERROR_WARNING_DIAGNOSTIC_GROUP_MAPPING,
  USER_STATUS_REPORT_FILE_NAME,
  USER_STATUS_FILE_NAME,
  RoleNames,
  USER_STATUS_REPORT_FILE_TITLE,
  DEFAULT_AGENCY_EDOC_LINK_EXPIRATION_TIME,
  CLINICIAN_CHANGE_REPORT_NAME,
  FILE_DOWNLOAD_SUCCESS, CLINICIAN_SUGGESTION_CALCULATION_REPORT_NAME, appSettings, appSettingsCheckbox,
} from "../common/Constants";
import { Paginator } from "primeng/paginator";
import { PortalFeatures } from "../models/agency";
import { SidenavService } from "../core/sidebar/sidenav.service";
import { UtilService } from "../services/util/util.service";
import { DatePipe } from "@angular/common";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { select, Store } from "@ngrx/store";
import { addAgency } from "../store/agency.actions";
import { AppState } from "../store/reducers";
import { LocalStorageService } from "../services/local-storage.service";
import { ActivatedRoute } from "@angular/router";
import { Dropdown } from "primeng/dropdown";
import { groupBy } from "lodash-es";

@Component({
  selector: "app-agencies",
  templateUrl: "./agencies.component.html",
  styleUrls: ["./agencies.component.scss"],
})
export class AgenciesComponent implements OnInit, OnDestroy {
  agencies: Agency[] = [];
  display = false;
  addAgencyForm: FormGroup;
  portalFeatures: PortalFeatures[] = portalFeatureValues;
  currentUser: User;
  selectedAgenciesToExport: Agency[] = [];
  searchAgency: string = "";
  totalAgencies: number = 0;
  agencyToUpdate: Agency;
  agencyLevel: any[] = [];
  currentImage: any;
  statusEnum = status;
  showEditForm: boolean = false;
  selectedIndex: any;
  subscriptions: Subscription[] = [];
  currentAgencyRole: { agency: string; role: string };
  pageNo: number = 0;
  noOfAgenciesPerRow: number = 10;
  rowsPerPageOptions: number[] = rowsPerPageOptions;
  isChangingPage: boolean = false;
  loading: boolean = false;
  agencyAdminUser = RoleID.AgencyAdmin;
  iohSuperAdmin = RoleID.Admin;
  iohAdmin = RoleID.iohAdmin;
  companyAdmin = RoleID.companyAdmin;
  countries = countries;
  agencyStatusFilterOptions: SelectItem[] = agencyStatusValues;
  selectedAgencyStatus;
  agencyLevelFilterOptions: SelectItem[] = agencyLevelValues;
  selectedAgencyLevel;
  agencyLevelFilter: string = "";
  agencyStatusFilter: string = "";
  showInvalidSMSTemplateError: boolean = false;
  showInvalidEmailTemplateError: boolean = false;
  AgencyFiltersOptions = AgencyFiltersOptions;
  @ViewChild("paginator") paginator: Paginator;
  @ViewChild("fileUpload", { static: false }) fileUpload: FileUpload;
  @ViewChild("dt") table: Table;
  @ViewChild("reportDropdown") reportDropdown: Dropdown;
  defaultSearchMinCharacter = DEFAULT_SEARCH_MIN_CHARACTER;
  diagnosisGroupOptions = DiagnosisGroupOptions;
  selectedDiagnosisGroups: string[] = [];
  companyPayload = [];
  companies = [];
  filteredCompanies: any[] = [];
  datePickerFormat = DATE_PICKER_FORMAT;
  datePickerPlaceholder = DATE_PICKER_PLACEHOLDER;
  rangeDates: Date[];
  dateRangeToExportAgencyData;
  disableDiagnosisGroups: boolean = true;
  agenciesAllowed: any[] = [];
  exportReportValues = [
    { label: "Errors & Warnings", icon: "pi pi-download", value: "Errors & Warnings" },
    { label: "Users & Devices", icon: "pi pi-download", value: "Users & Devices" },
    { label: "Users Status", icon: "pi pi-download", value: "User Status" },
    { label: "User Activation", icon: "pi pi-download", value: "User Activation" },
  ];
  componentLocalStorageKey = "agencyState";
  exportReportHeaders;
  workbook: Workbook;
  worksheet;
  agencyStatus = statusValues;
  COMPANY_ADMIN = RoleID.companyAdmin;
  agencies$: Observable<string[]>;
  selectedRows: any[] = defaultSelectedAgencyRows;
  visibleRows: any[] = AgencyListingRows;
  selectedAgencies = [];
  selectedAgencyIdFromParams = "";
  selectAll = false;
  appSettingCheckboxes = appSettingsCheckbox;

  constructor(
    private agencyService: AgencyService,
    private companyService: CompanyService,
    private messageHandlerService: MessageHandlerService,
    private primengConfig: PrimeNGConfig,
    private userDataManager: UserDataManager,
    private readonly progressBarService: ProgressBarService,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private sideNavService: SidenavService,
    private utilService: UtilService,
    private datePipe: DatePipe,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
  ) {
    this.agencies$ = this.store.pipe(select((state) => state.agencyState.agencies));

    this.agencyLevel = [
      { icon: "../../../assets/images/badge-gold.svg", value: "Gold" },
      { icon: "../../../assets/images/badge-silver.svg", value: "Silver" },
      { icon: "../../../assets/images/badge-bronze.svg", value: "Bronze" },
    ];
    this.matIconRegistry.addSvgIcon("status", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/active-inactive.svg"));
    this.matIconRegistry.addSvgIcon("delete", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/delete-item.svg"));
  }

  ngOnInit(): void {
    this.getAgencyIdFromParams();
    this.getReleaseVersion();
    this.getUserProfile();
    this.getDataFromLocalStorage();
    this.isAgencyAdmin();
    this.getCurrentAgencyRole();
    if (this.currentUser.is_super_admin || this.currentUser.is_IOH_admin) {
      this.getCompanies();
     // this.exportReportValues.push({ label: CLINICIAN_CHANGE_REPORT_NAME, icon: "pi pi-download", value: CLINICIAN_CHANGE_REPORT_NAME });
     // this.exportReportValues.push({ label: CLINICIAN_SUGGESTION_CALCULATION_REPORT_NAME, icon: "pi pi-download", value: CLINICIAN_SUGGESTION_CALCULATION_REPORT_NAME });
    }
    this.createAgencyForm();
    this.getSelectedRows();
  }

  getDataFromLocalStorage() {
    const agencyState = JSON.parse(this.localStorageService.get(this.componentLocalStorageKey));
    this.selectedAgencyLevel = agencyState?.selectedAgencyLevel ? agencyState?.selectedAgencyLevel : "";
    this.selectedAgencyStatus = agencyState?.selectedAgencyStatus ? agencyState?.selectedAgencyStatus : "";
    this.agencyStatusFilter = this.selectedAgencyStatus;
    this.agencyLevelFilter = this.selectedAgencyLevel;

    this.prePopulatePerPage();
  }

  prePopulateDate() {
    const global = JSON.parse(this.localStorageService.get("globalDates"));
    if (global) {
      this.rangeDates = [new Date(global.startDate), new Date(global.endDate)];
    }
  }

  prePopulatePerPage() {
    const global = JSON.parse(this.localStorageService.get("globalPageLimit"));
    if (global) {
      this.noOfAgenciesPerRow = global.perPage;
    }
  }

  getAgencyIdFromParams() {
    this.route.params.subscribe((params) => {
      this.selectedAgencyIdFromParams = params["id"];
    });
  }
  getAgencyValuesFromStore() {
    if (this.selectAll) {
      const agencyIds = this.agencies.map((agency) => {
        this.selectedAgenciesToExport.push(agency);
        return agency.id;
      });
      this.selectedAgencies = [...new Set(this.selectedAgencies.concat(agencyIds))];

      this.store.dispatch(addAgency({ agencies: this.selectedAgencies }));
    } else {
      this.subscriptions.push(
        this.agencies$.subscribe((storeAgencies) => {
          this.selectedAgenciesToExport = [];
          this.selectedAgencies = [];
          if (storeAgencies.length) {
            storeAgencies.forEach((storeAgency) => {
              const selectedAgencyMatch = this.agencies.find((agency) => agency.id == storeAgency);
              this.selectedAgencies.push(storeAgency);
              if (selectedAgencyMatch) {
                this.selectedAgenciesToExport.push(selectedAgencyMatch);
                this.prePopulateDate();
              }
            });
          }
        }),
      );
    }
  }

  onRowSelect() {
    const newValue: string[] = this.selectedAgenciesToExport.map((agency) => {
      return agency?.id;
    });

    this.selectedAgencies = [...new Set(newValue.concat(this.selectedAgencies))];
    this.store.dispatch(addAgency({ agencies: this.selectedAgencies }));
    this.resetFilters();
    this.prePopulateDate();
  }

  onRowUnSelect(event) {
    const index = this.selectedAgencies.indexOf(event?.data?.id);

    if (index !== -1) {
      this.selectedAgencies.splice(index, 1);
    }

    this.store.dispatch(addAgency({ agencies: this.selectedAgencies }));
    this.resetFilters();
    this.prePopulateDate();
  }

  onHeaderCheckboxToggle(event) {
    if (event?.checked) {
      this.selectAll = true;
      this.pageNo = 0;
      this.rowsPerPageOptions = [10, 20, 30, this.totalAgencies].sort();
      this.noOfAgenciesPerRow = this.totalAgencies;
      this.getAgencies();
    } else {
      this.selectAll = false;
      this.pageNo = 0;
      this.rowsPerPageOptions = [10, 20, 30].sort();
      this.noOfAgenciesPerRow = 10;
      this.selectedAgencies = [];
      this.selectedAgenciesToExport = [];
      this.store.dispatch(addAgency({ agencies: [] }));
      this.getAgencies();
    }
    // const agenciesIds = this.agencies.map((agency) => agency?.id);
    // if (event?.checked) {
    //   this.selectedAgencies = [...new Set(this.selectedAgencies.concat(agenciesIds))];
    // } else {
    //   agenciesIds.forEach((agenciesId) => {
    //     const index = this.selectedAgencies.indexOf(agenciesId);

    //     if (index !== -1) {
    //       this.selectedAgencies.splice(index, 1);
    //     }
    //   });
    // }

    // this.store.dispatch(addAgency({ agencies: this.selectedAgencies }));
    this.resetFilters();
    this.prePopulateDate();
  }

  resetFilters() {
    this.localStorageService.remove("patientState");
    this.localStorageService.remove("visitState");
    this.localStorageService.remove("companyState");
  }

  onSelectCompany(event) {
    if (event.value) {
      this.confirmationService.confirm({
        message: "Please confirm if you wanted to select this Company",
        header: "Confirm Company?",
        icon: "pi pi-cog",
        acceptLabel: "Confirm",
        rejectLabel: "Cancel",
        accept: () => {},
        reject: () => {
          this.addAgencyForm.get("companyId").patchValue("");
        },
      });
    }
  }

  companyRequired() {
    this.addAgencyForm.get("companyId").setValidators(Validators.required);
  }

  removeCompany() {
    this.confirmationService.confirm({
      message: "Please confirm if you wanted to delete this Company",
      header: "Delete Company?",
      icon: "pi pi-trash",
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
      acceptButtonStyleClass: "btn-delete",
      accept: () => {
        this.addAgencyForm.get("companyId").patchValue("");
      },
      reject: () => {},
    });
  }

  showRemoveCompany() {
    const company = this.addAgencyForm?.value?.companyId?.value;

    if (company && company !== "") {
      return true;
    } else {
      return false;
    }
  }

  getCompanies() {
    try {
      this.progressBarService.setProgressBarVisibility(true);
      this.subscriptions.push(
        this.companyService.getAllCompanies({ pagination: { pageNumber: 0, pageSize: 10000 } }).subscribe((res) => {
          if (res?.success) {
            this.companyPayload = res.content.data;
            const activeCompanies = res.content.data.filter((company) => company.status == UserStatus.active);
            this.companies = activeCompanies.map((item) => ({ label: item.name, value: item.id }));
          } else if (res) {
            this.messageHandlerService.generateToastMessage("error", "Communication error:  ", res?.errorMessage);
          }
          this.progressBarService.setProgressBarVisibility(false);
        }),
      );
    } catch (error) {
      this.progressBarService.setProgressBarVisibility(false);
      this.messageHandlerService.generateToastMessage("error", "Communication error:  ", error);
    }
  }

  filterCompanies(event) {
    let filtered: any[] = [];
    let query = event.query;
    const newUserCompanies = this.companies.filter((company) => company.label !== "All" && company.value !== "");

    for (let i = 0; i < newUserCompanies.length; i++) {
      let checkIfAlreadySelected = false;
      let company = newUserCompanies[i];
      if (company?.label?.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        const formData = this.addAgencyForm.value;

        if (company?.value && formData?.companyId?.value == company?.value) {
          checkIfAlreadySelected = true;
        }

        if (!checkIfAlreadySelected) {
          filtered.push(company);
        }
      }
    }

    this.filteredCompanies = filtered;
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  getCurrentAgencyRole() {
    this.subscriptions.push(
      this.userDataManager.activeAgencyRole$.subscribe((currentRole) => {
        this.currentAgencyRole = currentRole;
        if (
          this.currentAgencyRole.role == RoleID.Admin ||
          this.currentAgencyRole.role == RoleID.iohAdmin ||
          this.currentAgencyRole.role == RoleID.companyAdmin ||
          this.currentAgencyRole.role == RoleID.AgencyAdmin
        ) {
          this.getAgencies();
        } else {
          this.agencies = [];
        }
      }),
    );
  }

  getPortalFeatureObject(): any {
    let features = {};
    portalFeatureValues.forEach((portalFeature) => {
      features[portalFeature.name] = false;
    });
    return features;
  }

  createAgencyForm() {
    this.disableDiagnosisGroups = !this.disableDiagnosisGroups;
    this.selectedDiagnosisGroups = [];
    this.addAgencyForm = this.formBuilder.group({
      picture: [null, Validators.required],
      name: ["", Validators.required],
      shortName: ["", Validators.required],
      phoneNumber: this.formBuilder.group({
        phoneNumber: [""],
        countryCode: [countries[0].dial_code],
      }),
      portalFeatures: this.formBuilder.group(this.getPortalFeatureObject()),
      fax: this.formBuilder.group({
        fax: [""],
        countryCode: [countries[0].dial_code],
      }),
      email: ["", [Validators.required, Validators.email]],
      status: ["Active"],
      agencyLevel: [""],
      address: this.formBuilder.group({
        state: ["", Validators.required],
        city: ["", Validators.required],
        line1: ["", Validators.required],
        line2: [""],
        postalCode: ["", Validators.required],
      }),
      totalAttempts: [""],
      linkExpireDuration: [DEFAULT_AGENCY_EDOC_LINK_EXPIRATION_TIME],
      appSettings: this.formBuilder.group(appSettings),
      defaultDiagnosisGroup: [""],
      diagnosisGroups: [""],
      disableDiagnosisGroups: [this.disableDiagnosisGroups],
      SMSTemplate: [""],
      emailTemplate: [""],
      companyId: [""],
      isIOHHelp: true,
    });
    if (this.currentUser.is_super_admin || this.currentUser.is_IOH_admin) {
      this.companyRequired();
    }
  }

  async onSelect(event) {
    const file = event.files[0];

    const formData = new FormData();
    formData.append("file", file);

    if (event.currentFiles && event.currentFiles.length > 0) {
      this.progressBarService.setProgressBarVisibility(true);
      await this.agencyService.uploadFile(formData).subscribe((res) => {
        if (res?.message?.Location) {
          this.currentImage = res;
          this.addAgencyForm.get("picture").setValue(res.message.Location);
          this.fileUpload.clear();
        }
        this.progressBarService.setProgressBarVisibility(false);
      });
    }
  }

  async deleteFile() {
    await this.agencyService
      .deleteFile({
        bucketName: "grandcare",
        objectKey: this.addAgencyForm.value.picture,
      })
      .subscribe((res) => {
        if (res) {
          this.currentImage = null;
          this.fileUpload.clear();
          this.addAgencyForm.patchValue({ picture: null });
        }
      });
  }

  getUserProfile() {
    this.subscriptions.push(
      this.userDataManager.user$.subscribe((res) => {
        if (res) {
          this.currentUser = res;
        }
      }),
    );
  }

  getRoleBasedAgencyListing(role: string) {
    this.agencyService.getRoleBasedAgencyListing(role, this.currentUser.agency_roles).subscribe((res) => {
      if (res.content.data) {
        this.agencies = res.content.data;
      }
    });
  }

  getCompanyID() {
    if (this.currentUser.is_company_admin && this.currentUser.currentUserRole === RoleID.companyAdmin) {
      return this.currentUser.companyId;
    }
    return null;
  }

  // find agencies with pagination and search
  getAgencies() {
    try {
      this.progressBarService.setProgressBarVisibility(true);
      this.loading = true;
      let request = {
        companyId: this.getCompanyID(),
        search: this.searchAgency ? this.searchAgency : "",
        status: this.agencyStatusFilter,
        agencyLevel: this.agencyLevelFilter,
        agencyID: this.getAgencyID(),
        pagination: {
          // pageNumber: this.agencyStatusFilter === "" && this.agencyLevelFilter === "" ? this.pageNo : 0,
          // pageSize: this.noOfAgenciesPerRow,
          pageNumber: this.pageNo ,  pageSize: this.noOfAgenciesPerRow
        },
        selectedAgency: this.selectedAgencyIdFromParams,
      };
      this.subscriptions.push(
        this.agencyService.getAgenciesWithSearch(request).subscribe((res) => {
          if (res?.success) {
            this.agencies = res.content.data;
            this.totalAgencies = res.content.totalAgenciesCount;
            this.selectedAgenciesToExport = [];
            this.rangeDates = null;
            this.dateRangeToExportAgencyData = {};
            if (!this.isChangingPage) {
              this.changePage();
            }

            this.getAgencyValuesFromStore();

            if (this.selectedAgencyIdFromParams && this.agencies && this.agencies.length) {
              this.openEditUserForm(this.agencies[0]);
            }
          } else if (res) {
            this.messageHandlerService.generateToastMessage("error", "Communication error:  ", res?.errorMessage);
          }
          this.progressBarService.setProgressBarVisibility(false);
          this.loading = false;
        }),
      );
    } catch (error) {
      this.progressBarService.setProgressBarVisibility(false);
      this.loading = false;
      this.messageHandlerService.generateToastMessage("error", "Communication error:  ", error);
    }
  }

  getAgencyID() {
    if (this.currentAgencyRole.role == RoleID.AgencyAdmin) {
      return this.currentAgencyRole.agency;
    }
    return null;
  }

  searchAgencies() {
    if (this.searchAgency === undefined || this.searchAgency === null || this.searchAgency === "") {
      this.getAgencies();
    } else if (this.searchAgency && this.searchAgency.length >= this.defaultSearchMinCharacter) {
      this.pageNo = 0;
      this.getAgencies();
    }
  }

  // Check if the agency search field is empty
  isSearchEmpty() {
    if (this.searchAgency === undefined || this.searchAgency === null || this.searchAgency === "") {
      this.pageNo = 0;
      this.getAgencies();
    }
  }

  createAgency() {
    let newAgency = this.addAgencyForm.value;
    newAgency.createdBy = this.currentUser.aws_cognito_id;
    newAgency.createdAt = new Date();
    newAgency.linkExpireDuration = newAgency.linkExpireDuration ? newAgency.linkExpireDuration : DEFAULT_AGENCY_EDOC_LINK_EXPIRATION_TIME;
    newAgency.totalAttempts = newAgency.totalAttempts ? newAgency.totalAttempts : 0;

    if (this.currentUser.is_company_admin && this.currentUser.currentUserRole === RoleID.companyAdmin) {
      newAgency.companyId = this.currentUser.companyId;
    }

    if (this.currentUser.is_super_admin || this.currentUser.is_IOH_admin) {
      if (newAgency.companyId && "label" in newAgency.companyId && "value" in newAgency.companyId) {
        newAgency.companyId = newAgency.companyId.value;
      }
    }

    this.subscriptions.push(
      this.agencyService.createAgency(newAgency).subscribe((res) => {
        if (res.success) {
          this.getAgencies();
          this.display = false;
          this.messageHandlerService.generateToastMessage("success", "", "Agency Created Successfully");
          this.sideNavService.setEDocPurchase();
        } else {
          this.messageHandlerService.generateToastMessage("error", "", "Agency Not Created");
        }
      }),
    );
  }

  onSlideBarClose() {
    this.display = false;
    this.showEditForm = false;
    this.addAgencyForm.reset();
    this.portalFeatures[0].value = false;
  }

  checkIfEmailExists() {
    this.subscriptions.push(
      this.agencyService.findAgencyByEmail({ email: this.addAgencyForm.value.email }).subscribe((res) => {
        if (res.success && res.content.data.length) {
          this.messageHandlerService.generateToastMessage("error", "", EMAIL_ALREADY_REGISTERED_AGENCY);
        } else if (res.success && !res.content.data.length) {
          this.createAgency();
        } else {
          this.messageHandlerService.generateToastMessage("error", "", res.errorMessage);
        }
      }),
    );
  }

  openEditUserForm(agency) {
    this.selectedIndex = agency;
    this.selectedDiagnosisGroups = this.selectedIndex?.diagnosisGroups;
    this.disableDiagnosisGroups = this.selectedIndex?.disableDiagnosisGroups;
    //console.log('disableDiagnosisGroups === ', this.disableDiagnosisGroups);
    this.showEditForm = true;
    if (
      this.currentUser.is_super_admin ||
      this.currentUser.is_IOH_admin ||
      this.currentUser.is_company_admin ||
      (this.currentUser.defaultRole === RoleID.AgencyAdmin && this.agenciesAllowed?.includes(agency?.id))
    ) {
      this.display = true;
    }
    let agencyFeatures = agency?.portalFeatures;
    this.portalFeatures?.forEach((feature) => {
      feature.value = agencyFeatures ? agencyFeatures[feature.name] : false;
    });
    this.prepareEditAgencyForm();
    this.createEditAgencyForm();
  }

  onDeleteClick() {
    this.onSlideBarClose();
    this.confirmationService.confirm({
      message: "Please confirm if you wanted to delete Agency",
      header: "Delete Agency?",
      icon: "pi pi-trash",
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
      acceptButtonStyleClass: "btn-delete",
      accept: () => {
        this.deleteAgency();
      },
      reject: () => {},
    });
  }

  deleteAgency78() {
    if (this.currentUser.is_super_admin || this.currentUser.is_company_admin) {
      this.subscriptions.push(
        this.agencyService.deleteAgency(this.selectedIndex.id).subscribe(
          (res: any) => {
            this.getAgencies();
            this.messageHandlerService.generateToastMessage("success", "", "Agency Deleted Successfully");
          },
          (error) => {
            this.messageHandlerService.generateToastMessage("error", "", "Error In Deleting Agency");
          },
        ),
      );
    } else {
      this.messageHandlerService.generateToastMessage("error", "", "Only Admin can delete agency");
    }
  }

  deleteAgency() {
    this.subscriptions.push(
      this.agencyService.deleteAgency(this.selectedIndex.id).subscribe(
        (res: any) => {
          if (res?.success) {
            this.getAgencies();
            this.messageHandlerService.generateToastMessage("success", "", "Agency Deleted Successfully");
          } else {
            this.messageHandlerService.generateToastMessage("error", "", "Error In Deleting Agency: " + res?.message);
          }
        },
        (error) => {
          this.messageHandlerService.generateToastMessage("error", "", "Error In Deleting Agency");
        },
      ),
    );
  }

  prepareEditAgencyForm() {
    this.addAgencyForm = this.formBuilder.group({
      picture: [null, Validators.required],
      name: ["", Validators.required],
      shortName: ["", Validators.required],
      phoneNumber: this.formBuilder.group({
        phoneNumber: [""],
        countryCode: [""],
      }),
      portalFeatures: this.formBuilder.group(this.getPortalFeatureObject()),
      fax: this.formBuilder.group({
        fax: [""],
        countryCode: [""],
      }),
      email: ["", [Validators.required, Validators.email]],
      status: [""],
      agencyLevel: [""],
      address: this.formBuilder.group({
        state: ["", Validators.required],
        city: ["", Validators.required],
        line1: ["", Validators.required],
        line2: [""],
        postalCode: ["", Validators.required],
      }),
      totalAttempts: [""],
      linkExpireDuration: [""],
      appSettings: this.formBuilder.group(appSettings),
      defaultDiagnosisGroup: [""],
      disableDiagnosisGroups: [this.disableDiagnosisGroups],
      diagnosisGroups: [""],
      SMSTemplate: [""],
      emailTemplate: [""],
      companyId: [""],
      isIOHHelp: true,
      // tagLine: [''],
      // emailTemplate: ['']
    });
    if (this.currentUser.is_super_admin || this.currentUser.is_IOH_admin) {
      this.companyRequired();
    }
  }

  createEditAgencyForm() {
    this.addAgencyForm.patchValue({
      picture: this.selectedIndex.picture,
      name: this.selectedIndex.name,
      shortName: this.selectedIndex.shortName,
      phoneNumber: {
        phoneNumber: this.selectedIndex.phoneNumber.phoneNumber,
        countryCode: this.selectedIndex.phoneNumber.countryCode,
      },
      fax: {
        fax: this.selectedIndex.fax.fax,
        countryCode: this.selectedIndex.fax.countryCode,
      },
      email: this.selectedIndex.email,
      status: this.selectedIndex.status,
      agencyLevel: this.selectedIndex.agencyLevel,
      address: {
        state: this.selectedIndex.address.state,
        city: this.selectedIndex.address.city,
        line1: this.selectedIndex.address.line1,
        line2: this.selectedIndex.address.line2,
        postalCode: this.selectedIndex.address.postalCode,
      },
      totalAttempts: this.selectedIndex.totalAttempts ? this.selectedIndex.totalAttempts : 0,
      linkExpireDuration: this.selectedIndex.linkExpireDuration ? this.selectedIndex.linkExpireDuration : DEFAULT_AGENCY_EDOC_LINK_EXPIRATION_TIME,

      appSettings: {
        appSettings,...this.selectedIndex.appSettings,
      },
      defaultDiagnosisGroup: this.selectedIndex?.defaultDiagnosisGroup,
      diagnosisGroups: this.selectedIndex?.diagnosisGroups,
      disableDiagnosisGroups: this.selectedIndex?.disableDiagnosisGroups,
      SMSTemplate: this.selectedIndex?.SMSTemplate,
      emailTemplate: this.selectedIndex?.emailTemplate,
      companyId: this.companies.filter((e) => e.value === this.selectedIndex.companyId?._id)[0],
      isIOHHelp: this.selectedIndex?.isIOHHelp ? this.selectedIndex?.isIOHHelp : false,
      // tagLine: this.selectedIndex?.tagLine,
      // emailTemplate: this.selectedIndex?.emailTemplate
    });
  }

  getCompanySelectedIcon(index) {
    const selectedIndex = this.companyPayload.find((e) => e.id === index?.value);

    return selectedIndex?.logo ?? "";
  }

  onUpdateClick() {
    let updatedAgency = { ...this.selectedIndex, ...this.addAgencyForm.value };
    this.onSlideBarClose();
    this.confirmationService.confirm({
      message: "Please confirm if you wanted to save all the changes that you've made to this agency",
      header: "Confirm Changes?",
      icon: "pi pi-cog",
      acceptLabel: "Update",
      rejectLabel: "Cancel",
      accept: () => {
        this.updateAgency(updatedAgency);
      },
      reject: () => {},
    });
  }

  updateAgency(agencyToUpdate: Agency) {
    agencyToUpdate.linkExpireDuration = agencyToUpdate.linkExpireDuration
      ? agencyToUpdate.linkExpireDuration
      : DEFAULT_AGENCY_EDOC_LINK_EXPIRATION_TIME;
    agencyToUpdate.totalAttempts = agencyToUpdate.totalAttempts ? agencyToUpdate.totalAttempts : 0;

    if (this.currentUser.is_company_admin && this.currentUser.currentUserRole === RoleID.companyAdmin) {
      agencyToUpdate.companyId = this.currentUser.companyId;
    }

    if (this.currentUser.is_super_admin || this.currentUser.is_IOH_admin) {
      if (
        agencyToUpdate.companyId &&
        typeof agencyToUpdate.companyId !== "string" &&
        "label" in agencyToUpdate.companyId &&
        "value" in agencyToUpdate.companyId
      ) {
        agencyToUpdate.companyId = agencyToUpdate.companyId.value;
      }
    }

    this.subscriptions.push(
      this.agencyService.updateAgency(agencyToUpdate).subscribe(
        (res) => {
          this.getAgencies();
          this.messageHandlerService.generateToastMessage("success", "", "Agency Updated Successfully");
          this.sideNavService.setEDocPurchase();
        },
        (error) => {
          this.messageHandlerService.generateToastMessage("error", "", "Error In Updating Agency");
        },
      ),
    );
  }

  onDropDownChange(index: number, selectedOption) {
    if (selectedOption === "Edit") {
      this.selectedIndex = this.agencies[index];
      this.showEditForm = true;
      this.display = true;
      this.prepareEditAgencyForm();
      this.createEditAgencyForm();
    } else if (selectedOption === "Delete") {
      this.selectedIndex = this.agencies[index];
      this.onDeleteClick();
    }
  }

  changeStatus(index: number) {
    let agency: Agency = { ...this.agencies[index] };
    agency.status = agency.status === "Active" ? "Inactive" : "Active";
    this.updateAgency(agency);
  }

  onAddAgencyClick() {
    this.showEditForm = false;
    this.display = true;
    this.createAgencyForm();
  }

  paginate(event) {
    if (!this.isChangingPage) {
      this.utilService.setDataInLocalStorage("globalPageLimit", "perPage", event.rows);
      this.pageNo = event.page;
      this.noOfAgenciesPerRow = event.rows;
      this.getAgencies();
    }
  }

  changePage() {
    this.isChangingPage = true;
    setTimeout(() => {
      this.paginator.changePage(this.pageNo);
      this.isChangingPage = false;
    });
  }

  onChangeAgencyLevelFilter(event) {
    this.agencyLevelFilter = event.value;
    this.pageNo = 0;
    this.getAgencies();
    this.paginator.changePage(this.pageNo);
    this.utilService.setDataInLocalStorage(this.componentLocalStorageKey, "selectedAgencyLevel", this.selectedAgencyLevel);
  }

  onChangeAgencyStatusFilter(event) {
    this.agencyStatusFilter = event.value;
    this.pageNo = 0;
    this.getAgencies();
    this.paginator.changePage(this.pageNo);
    this.utilService.setDataInLocalStorage(this.componentLocalStorageKey, "selectedAgencyStatus", this.selectedAgencyStatus);
  }

  onChangeAppSettingValue(event: any, controlName: string) {
    this.addAgencyForm.patchValue({ appSettings: { [controlName]: event.checked } });
  }

  onChangePortalFeatureValue(event: any, controlName: string) {
    this.addAgencyForm.patchValue({ portalFeatures: { [controlName]: event.checked } });
  }

  checkNameAndAgencyNameInput() {
    this.showInvalidSMSTemplateError = this.addAgencyForm.get("SMSTemplate").value.length > 135;
  }

  checkEmailTemplateLength() {
    this.showInvalidEmailTemplateError = this.addAgencyForm.get("emailTemplate").value.length > 135;
  }

  checkDiagnosisGroupVisibility(event) {
    this.disableDiagnosisGroups = event.checked;
  }

  onExportData() {
    let agenciesToExportIds = this.utilService.getSelectedAgenciesIds(this.selectedAgenciesToExport);
    if (agenciesToExportIds && agenciesToExportIds.length === 0) {
      this.messageHandlerService.generateToastMessage("error", "", `Please select agencies to generate the report`);
      return;
    }
    let requestPayload = {
      agencyIds: this.getAllowedAgencyIds(agenciesToExportIds),
      searchByDate: this.dateRangeToExportAgencyData,
      sortBy: "",
    };

    this.getAgnecyDataToExport(requestPayload);
  }

  getAllowedAgencyIds(agenciesToExportIds) {
    if (this.agenciesAllowed && this.agenciesAllowed.length && this.agenciesAllowed[0] != undefined) {
      let agencyIds: string[] = [];
      agenciesToExportIds.forEach((obj) => {
        if (this.agenciesAllowed.includes(obj)) {
          agencyIds.push(obj);
        }
      });
      return agencyIds;
    } else {
      return agenciesToExportIds;
    }
  }

  setDatesForExport() {
    let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
    let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);

    this.dateRangeToExportAgencyData = {
      startDate: this.utilService.convertDateFilterToISOString(startDateFilter, true),
      endDate: this.utilService.convertDateFilterToISOString(endDateFilter, false), // change false to true 7/6/2024
    };

    this.utilService.setDataInLocalStorage("globalDates", "startDate", this.rangeDates[0]);
    this.utilService.setDataInLocalStorage("globalDates", "endDate", this.rangeDates[1]);
  }

  onCalendarClear() {
    this.rangeDates = null;
    this.dateRangeToExportAgencyData = null;
    this.localStorageService.remove("globalDates");
  }

  getUniqueAndDupObject(array) {
    const uniqObjs = [];
    const dupeObjs = [];

    array.forEach((obj) =>
      [uniqObjs, dupeObjs][+(array.map((obj) => obj.companyId._id).filter((id) => id === obj.companyId._id).length > 1)].push(obj),
    );

    return {
      unique: uniqObjs,
      duplicate: dupeObjs,
    };
  }

  getAgnecyDataToExport(requestPayload) {
    this.agencyService.getAgencyErrorsAndWarningsDataToExport(requestPayload).subscribe(
      (res: AgencyExport) => {
        if (res && res.agencies) {
          this.workbook = new Workbook();
          const worksheet = this.workbook.addWorksheet("Errors & Warnings");
          this.generateExcelReportData(res, worksheet);
          this.generateDiagnosticGroup(res);
          this.exportExcelReport();
        } else {
          this.messageHandlerService.generateToastMessage("error", "", `An error occurred`);
        }
      },
      (error) => {
        this.messageHandlerService.generateToastMessage("error", "", `An error occurred ${error}`);
      },
    );
  }

  getReportHeadersForAgency() {
    return [
      { header: "", key: "", width: 25 },
      { header: "Agency", key: "Agency", width: 50 },
      { header: "Patients", key: "Patients", width: 20 },
      { header: "io Patients", key: "io Patients", width: 20 },
      { header: "io Visits", key: "io Visits", width: 20 },
      { header: "Errors", key: "Errors", width: 20 },
      { header: "Warnings", key: "Warnings", width: 20 },
      { header: "Consistency Warnings", key: "Consistency Warnings", width: 25 },
    ];
  }

  getReportHeadersForAgencyClinicians() {
    return [
      { header: "Clinician", key: "Clinician", width: 25 },
      { header: "Agency", key: "Agency", width: 50 },
      { header: "Patients", key: "Patients", width: 20 },
      { header: "io Patients", key: "io Patients", width: 20 },
      { header: "io Visits", key: "io Visits", width: 20 },
      { header: "Errors", key: "Errors", width: 20 },
      { header: "Warnings", key: "Warnings", width: 20 },
      { header: "Consistency Warnings", key: "Consistency Warnings", width: 25 },
    ];
  }

  getReportHeadersForAgencyDiagnosticGroups() {
    return [
      { header: "Diagnostic Group", key: "Diagnostic Group", width: 25 },
      { header: "Agency", key: "Agency", width: 50 },
      { header: "Patients", key: "Patients", width: 20 },
      { header: "io Patients", key: "io Patients", width: 20 },
      { header: "io Visits", key: "io Visits", width: 20 },
      { header: "Errors", key: "Errors", width: 20 },
      { header: "Warnings", key: "Warnings", width: 20 },
      { header: "Consistency Warnings", key: "Consistency Warnings", width: 25 },
    ];
  }

  getReportHeadersForDiagnosticGroups() {
    return [
      { header: "Diagnostic Group", key: "Diagnostic Group", width: 25 },
      { header: "Agency", key: "Agency", width: 50 },
      { header: "Total # Patients from dx group", key: "Total # Patients from dx group", width: 30 },
      { header: "Total # errors", key: "Total # errors", width: 20 },
      { header: "Total # warnings", key: "Total # warnings", width: 20 },
    ];
  }

  generateDiagnosticGroup(agencyData) {
    const newWorksheet = this.workbook.addWorksheet("Diagnostic Groups");
    this.addFilterDatesRow(newWorksheet, "warning");
    if (agencyData?.agencies && agencyData?.agencies[0] != undefined) {
      for (let agency of agencyData?.agencies) {
        this.prepareDiagnosticGroupWithHeaders(agency?.usersData[0]?.diagnosticGroupsData, agency.agencyName, agency.visits, newWorksheet);
      }
    }
  }

  generateExcelReportData(agencyData, worksheet) {
    this.addFilterDatesRow(worksheet, "warning");
    if (agencyData?.agencies && agencyData?.agencies[0] != undefined) {
      for (let agency of agencyData?.agencies) {
        this.prepareAgencyDatawithHeaders(agency, worksheet);
      }
    }
  }

  prepareDiagnosticGroupWithHeaders(data, agencyName, visits, worksheet) {
    this.addDiagnosticGroupHeaders(worksheet);
    this.addDiagnosticGroupData(data, agencyName, visits, worksheet);
  }

  addDiagnosticGroupHeaders(worksheet) {
    let headers = this.getReportHeadersForDiagnosticGroups();
    this.addHeaderRow(headers, worksheet);
  }

  addDiagnosticGroupData(diagnosisGroup, agencyName, visits, worksheet) {
    const mergeDiagnosticGroups = ["default", "other"];
    const mergeUnchangedDiagnosticGroups = ["default", "Other"];
    const filterDiagnosticGroups = diagnosisGroup.filter((dg) => mergeDiagnosticGroups.includes(dg?.group?.toLowerCase() ?? ""));

    const groupVisits = this.filterBy(visits, "group");

    let ungroup_dg = [];

    diagnosisGroup.forEach((obj) => {
      if (obj != 0 && !mergeDiagnosticGroups.includes(obj.group.toLowerCase())) {
        let diagnosisGroupData = {
          name: ERROR_WARNING_DIAGNOSTIC_GROUP_MAPPING.find((e) => e.group_values.includes(obj.group))?.group_name || obj?.group || "",
          agency_name: agencyName || "",
          ttlPatients: obj?.totalPatients || obj?.totalPatients === 0 ? obj?.totalPatients : "",
          error: groupVisits[obj?.group]?.error || "",
          warn: groupVisits[obj?.group]?.warn || "",
        };
        ungroup_dg.push(diagnosisGroupData);
      }
    });

    const groupEntries = groupBy(ungroup_dg, "name");

    for (const [k, v] of Object.entries(groupEntries)) {
      const name = k;
      const agency_name = v[0].agency_name;
      let ttlPatient = 0;
      let error = 0;
      let warning = 0;

      v.forEach((fdg) => {
        ttlPatient += fdg?.ttlPatients || fdg?.ttlPatients === 0 ? fdg?.ttlPatients : 0;
        error += fdg?.error || fdg?.error === 0 ? fdg?.error : 0;
        warning += fdg?.warn || fdg?.warn === 0 ? fdg?.warn : 0;
      });

      const r = [name, agency_name, ttlPatient, error, warning];

      worksheet.addRow(r);
    }

    if (filterDiagnosticGroups.length > 0) {
      let ttlPatient = 0;
      let error = 0;
      let warning = 0;

      filterDiagnosticGroups.forEach((fdg) => {
        ttlPatient += fdg?.totalPatients || fdg?.totalPatients === 0 ? fdg?.totalPatients : 0;
      });

      for (const v of mergeUnchangedDiagnosticGroups) {
        if (groupVisits[v]) {
          error += groupVisits[v].error;
          warning += groupVisits[v].warn;
        }
      }

      let diagnosisGroupData = ["Other", agencyName || "", ttlPatient, error, warning];
      worksheet.addRow(diagnosisGroupData);
    }

    this.addblankRows(worksheet);
  }

  prepareAgencyDatawithHeaders(agency, worksheet) {
    this.addAgencyHeaders(worksheet);
    this.addAgencyData(agency, worksheet);
  }

  addAgencyHeaders(worksheet) {
    let headers = this.getReportHeadersForAgency();
    this.addHeaderRow(headers, worksheet);
  }

  calculateInconsistencyWarnings(visits: any[]) {
    let crossRulesIssuesCount = 0;
    visits.forEach((visit) => {
      let crossRulesIssues = [];
      const issues = visit?.issues || [];

      issues.forEach((issue) => {
        if (issue?.crossRule && issue?.crossRuleType === "impact") {
          crossRulesIssues.push(issue);
        }
      });

      let handledIssues = [];

      for (let i = 0; i < crossRulesIssues.length; i++) {
        for (let j = 0; j < crossRulesIssues[i].otherValues.length; j++) {
          let id1 = crossRulesIssues[i].question + crossRulesIssues[i].otherValues[j].title;

          let id2 = crossRulesIssues[i].otherValues[j].title + crossRulesIssues[i].question;

          if (handledIssues.includes(id1) || handledIssues.includes(id2)) {
            continue;
          } else {
            handledIssues.push(id1);
            handledIssues.push(id2);
            crossRulesIssuesCount++;
          }
        }
      }
    });

    return crossRulesIssuesCount;
  }

  calculateErrorsAndWarnings(visits: any[], type: "warn" | "error") {
    let count = 0;
    visits.forEach((visit) => {
      if (type === "error") {
        count += visit?.issues?.filter((e: any) => e.type === QUESTIONAIR_TYPE_ERROR && e.crossRuleType !== IMPACT).length;
      } else if (type === "warn") {
        count += visit?.issues?.filter(
          (e: any) => e.type === QUESTIONAIR_TYPE_WARN || (e.type === QUESTIONAIR_TYPE_WARNING && e.crossRuleType !== IMPACT),
        ).length;
      }
    });
    return count;
  }

  addAgencyData(agency, worksheet) {
    const IW = this.calculateInconsistencyWarnings(agency.visits);
    let agencyData = [
      "Total",
      agency.agencyName || "",
      agency.totalPatients || agency.totalPatients === 0 ? agency.totalPatients : "",
      agency.iohPatients || agency.iohPatients === 0 ? agency.iohPatients : "",
      agency.visits.length || 0,
      this.calculateErrorsAndWarnings(agency.visits, "error"),
      this.calculateErrorsAndWarnings(agency.visits, "warn"),
      IW || 0,
    ];
    worksheet.addRow(agencyData);
    this.addblankRows(worksheet);
    if (agency.usersData && agency?.usersData[0]?.clinicians && agency?.usersData[0]?.clinicians[0] != undefined) {
      this.prepareAgencyCliniciansDatawithHeaders(agency?.usersData[0], agency.agencyName, agency.visits, worksheet);
    }

    if (agency.usersData && agency?.usersData[0]?.diagnosticGroupsData && agency?.usersData[0]?.diagnosticGroupsData[0] != undefined) {
      this.prepareAgencyDiagnosticGroupsDatawithHeaders(agency?.usersData[0]?.diagnosticGroupsData, agency.agencyName, agency.visits, worksheet);
    }
  }

  prepareAgencyCliniciansDatawithHeaders(clinicians, agencyName, visits, worksheet) {
    this.addAgencyCliniciansHeaders(worksheet);
    this.addAgencyCliniciansData(clinicians, agencyName, visits, worksheet);
  }

  addAgencyCliniciansHeaders(worksheet) {
    let headers = this.getReportHeadersForAgencyClinicians();
    this.addHeaderRow(headers, worksheet);
  }

  addAgencyCliniciansData(cliniciansData, agencyName, visits, worksheet) {
    const groupVisits = this.filterBy(visits, "userId");
    cliniciansData.clinicians.forEach((obj) => {
      let agencyCliniciansData = [
        obj.userName || "",
        agencyName || "",
        obj.totalPatientsForClinician || obj.totalPatientsForClinician === 0 ? obj.totalPatientsForClinician : "",
        obj.IOPatientsCount || obj.IOPatientsCount === 0 ? obj.IOPatientsCount : "",
        obj.totalUniqueSubmissionOfClinician || obj.totalUniqueSubmissionOfClinician === 0 ? obj.totalUniqueSubmissionOfClinician : "",
        groupVisits[obj?.userId]?.error || "",
        groupVisits[obj?.userId]?.warn || "",
        groupVisits[obj?.userId]?.inconsitency || "",
      ];
      worksheet.addRow(agencyCliniciansData);
    });
    this.addblankRows(worksheet);
  }

  prepareAgencyDiagnosticGroupsDatawithHeaders(diagnosisGroups, agencyName, visits, worksheet) {
    this.addAgencyDiagnosticGroupHeaders(worksheet);
    this.addAgencyDiagnosticGroupsData(diagnosisGroups, agencyName, visits, worksheet);
  }

  addAgencyDiagnosticGroupHeaders(worksheet) {
    let headers = this.getReportHeadersForAgencyDiagnosticGroups();
    this.addHeaderRow(headers, worksheet);
  }

  filterBy(visits, field) {
    let inconsistencyWarnings = {};
    const groupVisits = groupBy(visits, field);

    for (const [k, v] of Object.entries(groupVisits)) {
      inconsistencyWarnings = {
        ...inconsistencyWarnings,
        [k]: {
          error: this.calculateErrorsAndWarnings(v, "error"),
          warn: this.calculateErrorsAndWarnings(v, "warn"),
          inconsitency: this.calculateInconsistencyWarnings(v),
        },
      };
    }

    return inconsistencyWarnings;
  }

  addAgencyDiagnosticGroupsData(diagnosisGroup, agencyName, visits, worksheet) {
    const mergeDiagnosticGroups = ["default", "other"];
    const mergeUnchangedDiagnosticGroups = ["default", "Other"];
    const filterDiagnosticGroups = diagnosisGroup.filter((dg) => mergeDiagnosticGroups.includes(dg?.group?.toLowerCase() ?? ""));

    const groupVisits = this.filterBy(visits, "group");

    diagnosisGroup.forEach((obj) => {
      if (obj != 0 && !mergeDiagnosticGroups.includes(obj.group.toLowerCase())) {
        let diagnosisGroupData = [
          obj?.group || "",
          agencyName || "",
          obj?.totalPatients || obj?.totalPatients === 0 ? obj?.totalPatients : "",
          obj?.totalIOHPatients || obj?.totalIOHPatients === 0 ? obj?.totalIOHPatients : "",
          obj?.diagnosticErrorsData.totalUniqueSubmissionsForGroup || obj?.diagnosticErrorsData.totalUniqueSubmissionsForGroup === 0
            ? obj?.diagnosticErrorsData.totalUniqueSubmissionsForGroup
            : "",
          groupVisits[obj?.group]?.error || "",
          groupVisits[obj?.group]?.warn || "",
          groupVisits[obj?.group]?.inconsitency || "",
        ];
        worksheet.addRow(diagnosisGroupData);
      }
    });

    if (filterDiagnosticGroups.length > 0) {
      let ttlPatient = 0;
      let ttlioPatient = 0;
      let ttlioVisit = 0;
      let error = 0;
      let warning = 0;
      let inconsistencyWarning = 0;

      filterDiagnosticGroups.forEach((fdg) => {
        ttlPatient += fdg?.totalPatients || fdg?.totalPatients === 0 ? fdg?.totalPatients : 0;
        ttlioPatient += fdg?.totalIOHPatients || fdg?.totalIOHPatients === 0 ? fdg?.totalIOHPatients : 0;
        ttlioVisit +=
          fdg?.diagnosticErrorsData.totalUniqueSubmissionsForGroup || fdg?.diagnosticErrorsData.totalUniqueSubmissionsForGroup === 0
            ? fdg?.diagnosticErrorsData.totalUniqueSubmissionsForGroup
            : 0;
      });

      for (const v of mergeUnchangedDiagnosticGroups) {
        if (groupVisits[v]) {
          error += groupVisits[v].error;
          warning += groupVisits[v].warn;
          inconsistencyWarning += groupVisits[v].inconsitency;
        }
      }

      let diagnosisGroupData = ["other", agencyName || "", ttlPatient, ttlioPatient, ttlioVisit, error, warning, inconsistencyWarning];
      worksheet.addRow(diagnosisGroupData);
    }

    this.addblankRows(worksheet);
  }

  addblankRows(worksheet) {
    for (let i = 0; i <= 1; i++) {
      worksheet.addRow([]);
    }
  }

  getCompanyNameForReport() {
    let companyName;
    if (this.selectedAgenciesToExport && this.selectedAgenciesToExport.length) {
      const allHaveSameCompanyId = this.selectedAgenciesToExport.every(
        (obj) => obj?.companyId?._id === this.selectedAgenciesToExport[0]?.companyId?._id,
      );
      if (allHaveSameCompanyId) {
        companyName = this.selectedAgenciesToExport[0]?.companyId?.name;
      }
    }

    return companyName;
  }

  exportExcelReport() {
    const companyName = this.getCompanyNameForReport();
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      let titleString = "";
      if (this.selectedAgenciesToExport && this.selectedAgenciesToExport.length === 1) {
        titleString = `${this.selectedAgenciesToExport[0].name}-${ERROR_WARNING_REPORT_FILE_NAME}-`;
      } else if (this.selectedAgenciesToExport && this.selectedAgenciesToExport.length > 1) {
        const rArr = this.getUniqueAndDupObject(this.selectedAgenciesToExport);

        if (rArr.duplicate && rArr.duplicate.length > 0 && rArr.unique && rArr.unique.length <= 0) {
          titleString = `${this.selectedAgenciesToExport[0].companyId.name}-${ERROR_WARNING_REPORT_FILE_NAME}-`;
        } else if (rArr.unique && rArr.unique.length > 0) {
          titleString = `${ERROR_WARNING_REPORT_FILE_NAME}-`;
        }
      }
      fs.saveAs(blob, titleString + this.datePipe.transform(new Date(), dateFormatStartWithYearWithOutDashes) + EXCEL_EXTENSION);
    });
  }

  addHeaderRow(headers, worksheet) {
    const headerRowValues = headers?.map((obj) => obj.key);
    let headersRow = worksheet.addRow(headerRowValues);
    headersRow.eachCell((row, colNumber) => {
      row.style = {
        font: { bold: true },
        ...(colNumber !== 1
          ? {
              fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFF00" },
                bgColor: { argb: "FFFF00" },
              },
            }
          : null),
      };
    });
    headers?.forEach((header, index) => {
      const column = worksheet.getColumn(index + 1); // Columns are 1-indexed
      column.width = header.width; // Set the width from the response
      column.alignment = { vertical: "middle", horizontal: "left" };
    });
  }

  addHeaderRowForUsers(worksheet) {
    const worksheetHeaders = this.getExcelReportHeadersForUsers();
    const headerRowValues = worksheetHeaders.map((obj) => obj.key);
    let headersRow = worksheet.addRow(headerRowValues);

    headersRow.eachCell((row, colNumber) => {
      row.style = {
        font: { bold: true },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
          bgColor: { argb: "FFFF00" },
        },
      };
    });

    worksheetHeaders.forEach((header, index) => {
      const column = worksheet.getColumn(index + 1); // Columns are 1-indexed
      column.width = header.width; // Set the width from the response
      column.alignment = { vertical: "middle", horizontal: "left" };
    });
  }

  addHeaderRowForUserStatus(worksheet) {
    const worksheetHeaders = this.getExcelReportHeadersForUserStatus();
    const headerRowValues = worksheetHeaders.map((obj) => obj.key);
    let headersRow = worksheet.addRow(headerRowValues);

    headersRow.eachCell((row, colNumber) => {
      row.style = {
        font: { bold: true },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
          bgColor: { argb: "FFFF00" },
        },
      };
    });

    worksheetHeaders.forEach((header, index) => {
      const column = worksheet.getColumn(index + 1); // Columns are 1-indexed
      column.width = header.width; // Set the width from the response
      column.alignment = { vertical: "middle", horizontal: "left" };
    });
  }

  addFilterDatesRow(worksheet, type: "user" | "warning" | "deleted") {
    let dateRangeString = "";
    let titleString = "";
    let filterRow;
    //const startDate = this.utilService.convertDateFilterToISOString(this.rangeDates[0],true);
    if (this.dateRangeToExportAgencyData?.startDate && !this.dateRangeToExportAgencyData?.endDate) {
      const startDate = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
      const endDate = this.datePipe.transform(new Date().toISOString(), dateFormatStartWithYear); //this.utilService.convertDateFilterToISOString(new Date(),false);
      //this.datePipe.transform(new Date().toISOString(), dateFormatStartWithYear);
      dateRangeString = `Date Range: ${startDate} - ${endDate}`;
    } else if (this.dateRangeToExportAgencyData?.startDate && this.dateRangeToExportAgencyData?.endDate) {
      const startDate = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
      const endDate = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear); //
      dateRangeString = `Date Range: ${startDate} - ${endDate}`;
    } else if (!this.dateRangeToExportAgencyData?.startDate && !this.dateRangeToExportAgencyData?.endDate) {
      const startDate = this.datePipe.transform(new Date().toISOString(), dateFormatStartWithYear);
      dateRangeString = `Date Range: ---- - ${startDate}`;
    }

    if (this.selectedAgenciesToExport && this.selectedAgenciesToExport.length === 1) {
      if (type === "user") {
        titleString = `Report: ${this.selectedAgenciesToExport[0].name} - ${USER_DEVICES_REPORT_FILE_NAME}`;
      } else if (type === "warning") {
        titleString = `Report: ${this.selectedAgenciesToExport[0].name} - ${ERROR_WARNING_REPORT_FILE_NAME}`;
      } else if (type === "deleted") {
        titleString = `Report: ${this.selectedAgenciesToExport[0].name} - ${USER_STATUS_FILE_NAME}`;
      }
      //titleString = `Report: ${this.selectedAgenciesToExport[0].name} - ${type === 'user' ? USER_DEVICES_REPORT_FILE_NAME : ERROR_WARNING_REPORT_FILE_NAME}`
    } else if (this.selectedAgenciesToExport && this.selectedAgenciesToExport.length > 1) {
      const rArr = this.getUniqueAndDupObject(this.selectedAgenciesToExport);

      if (rArr.duplicate && rArr.duplicate.length > 0 && rArr.unique && rArr.unique.length <= 0) {
        //titleString = `Report: ${this.selectedAgenciesToExport[0].companyId.name} - ${type === 'user' ? USER_DEVICES_REPORT_FILE_NAME : ERROR_WARNING_REPORT_FILE_NAME}`
        if (type === "user") {
          titleString = `Report: ${this.selectedAgenciesToExport[0].companyId.name} - ${USER_DEVICES_REPORT_FILE_NAME}`;
        } else if (type === "warning") {
          titleString = `Report: ${this.selectedAgenciesToExport[0].companyId.name} - ${ERROR_WARNING_REPORT_FILE_NAME}`;
        } else if (type === "deleted") {
          titleString = `Report: ${this.selectedAgenciesToExport[0].companyId.name} - ${USER_STATUS_FILE_NAME}`;
        }
      } else if (rArr.unique && rArr.unique.length > 0) {
        //titleString = `Report: ${type === 'user' ? USER_DEVICES_REPORT_FILE_NAME : ERROR_WARNING_REPORT_FILE_NAME}`
        if (type === "user") {
          titleString = `Report: ${USER_DEVICES_REPORT_FILE_NAME}`;
        } else if (type === "warning") {
          titleString = `Report:  ${ERROR_WARNING_REPORT_FILE_NAME}`;
        } else if (type === "deleted") {
          titleString = `Report:  ${USER_STATUS_FILE_NAME}`;
        }
      }
    }

    filterRow = worksheet.addRow([titleString]);
    worksheet.mergeCells(`A${filterRow.number}:Z${filterRow.number}`);
    filterRow = worksheet.addRow([dateRangeString]);
    worksheet.mergeCells(`A${filterRow.number}:Z${filterRow.number}`);
    worksheet.addRow([]);
  }

  generateExcelReportDataForUserDevices(agencyData) {
    const exportData: any[] = [];
    if (agencyData) {
      for (let e of agencyData) {
        let exportRow = [
          e?.agency_roles && e?.agency_roles.length ? e?.agency_roles[0]?.agency?.agencyName ?? "" : "",
          e?.firstName ?? "",
          e?.lastName ?? "",
          e?.status ?? "",
          e?.email ?? "",
          e?.is_company_admin ? this.companyAdmin : e?.defaultRole ?? "",
          e?.androidVersion ?? "",
          e?.desktopVersion ?? "",
          e?.platform ?? "",
          e?.lastLogin ?? "",
          e?.lastActivity ?? "",
          e?.lastAction ?? "",
          e?.userSettings?.isMultiAgenciesAllowed ?? "",
          e?.createdByUsers[0] ? e?.createdByUsers[0]?.firstName[0] + " " + e?.createdByUsers[0]?.lastName[0] ?? "" : "",
          e?.createdAt ?? "",
          e?.userSettings?.logging ?? "",
          e?.userSettings?.loggingView ?? "",
          e?.permissions && e?.permissions.length ? e?.permissions.join(", ") : "",
        ];
        exportData.push(exportRow);
      }
    }
    return exportData;
  }

  getExcelReportHeadersForUsers() {
    return [
      { header: "Agency", key: "Agency", width: 20 },
      { header: "FirstName", key: "FirstName", width: 30 },
      { header: "LastName", key: "LastName", width: 30 },
      { header: "Status", key: "Status", width: 20 },
      { header: "Email", key: "Email", width: 50 },
      { header: "DefaultRole", key: "DefaultRole", width: 30 },
      { header: "Android Version", key: "Android Version", width: 20 },
      { header: "Desktop Version", key: "Desktop Version", width: 20 },
      { header: "Platform", key: "Platform", width: 20 },
      { header: "Last Login", key: "Last Login", width: 30 },
      { header: "Last Activity", key: "Last Activity", width: 30 },
      { header: "Last Action", key: "Last Action", width: 30 },
      { header: "Multi Agencies Allowed", key: "Multi Agencies Allowed", width: 20 },
      { header: "Created By", key: "Created By", width: 50 },
      { header: "Created At", key: "Created At", width: 30 },
      { header: "Logging", key: "Logging", width: 20 },
      { header: "Logging View", key: "Logging View", width: 20 },
      { header: "Permissions", key: "Permissions", width: 20 },
    ];
  }

  exportExcelReportForUsers(dataToExport) {
    const companyName = this.getCompanyNameForReport();

    // creating workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("User Devices");

    //adding filter dates row
    this.addFilterDatesRow(worksheet, "user");

    //adding header row
    this.addHeaderRowForUsers(worksheet);

    //adding data row
    worksheet.addRows(dataToExport);

    //writing data to excel file
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(
        blob,
        (this.selectedAgenciesToExport.length === 1
          ? `${this.selectedAgenciesToExport[0].name}-${USER_DEVICES_REPORT_FILE_NAME}`
          : companyName
          ? `${companyName}-${USER_DEVICES_REPORT_FILE_NAME}`
          : USER_DEVICES_REPORT_FILE_NAME) +
          "-" +
          this.datePipe.transform(new Date(), dateFormatStartWithYearWithOutDashes) +
          EXCEL_EXTENSION,
      );
    });
  }

  getAgencyUserDataToExport(requestPayload: any) {
    this.agencyService.getAgencyUsersAndDevicesDataToExport(requestPayload).subscribe(
      (res) => {
        if (res && res.content && res.content.length) {
          let dataToExport = this.generateExcelReportDataForUserDevices(res.content);
          this.exportExcelReportForUsers(dataToExport);
        } else {
          this.messageHandlerService.generateToastMessage("error", "", `No data matching the search criteria is found to export`);
        }
      },
      (error) => {
        this.messageHandlerService.generateToastMessage("error", "", `An error occurred ${error}`);
      },
    );
  }

  onExportUserInfo() {
    let agenciesToExportIds = this.utilService.getSelectedAgenciesIds(this.selectedAgenciesToExport);
    if (agenciesToExportIds && agenciesToExportIds.length === 0) {
      this.messageHandlerService.generateToastMessage("error", "", `Please select agencies to generate the report`);
      return;
    }
    let requestPayload = {
      agencyIds: this.getAllowedAgencyIds(agenciesToExportIds),
      searchByDate: this.dateRangeToExportAgencyData,
      sortBy: "",
      IOH: false,
    };

    this.getAgencyUserDataToExport(requestPayload);
  }

  isAgencyAdmin() {
    //if(this.currentUser.defaultRole == RoleID.AgencyAdmin) {
    this.currentUser?.agency_roles?.map((role) => {
      if (role.roles.includes(RoleID.AgencyAdmin)) {
        this.agenciesAllowed.push(role.agency.agencyID);
      }
    });
    // }
  }

  getReleaseVersion() {
    this.utilService.getReleaseVersion().subscribe((res) => {
      if (res && res.success) {
        console.log("res", CURRENT_RELEASE_VERSION);
        console.log("res.content.data.version", res.content.data.version);
        if (res.content.data.version !== CURRENT_RELEASE_VERSION) {
          setInterval(() => {
            window.location.reload();
          }, RELOAD_PAGE_AFTER_RELEASE || 5000);
        }
      }
    });
  }

  onSelectedRowsChange() {
    let agencyListingRows = { selectedAgencyListingRows: [] };

    if (this.localStorageService.get("agencySettings")) agencyListingRows = JSON.parse(this.localStorageService.get("agencySettings"));

    agencyListingRows.selectedAgencyListingRows = this.selectedRows;
    this.localStorageService.set("agencySettings", JSON.stringify(agencyListingRows));
  }

  getSelectedRows() {
    if (!this.localStorageService.get("agencySettings")) {
      this.selectedRows = defaultSelectedAgencyRows;
      this.localStorageService.set("agencySettings", JSON.stringify({ selectedAgencyListingRows: this.selectedRows }));
    } else {
      this.selectedRows = JSON.parse(this.localStorageService.get("agencySettings"))?.selectedAgencyListingRows
        ? JSON.parse(this.localStorageService.get("agencySettings"))?.selectedAgencyListingRows
        : defaultSelectedAgencyRows;
    }
  }

  isRowVisible(rowName: string) {
    if (this.selectedRows && this.selectedRows.length) return this.selectedRows.find((selectedRow) => selectedRow === rowName);
  }

  onExportUserStatusReport() {
    let agenciesToExportIds = this.utilService.getSelectedAgenciesIds(this.selectedAgenciesToExport);
    if (agenciesToExportIds && agenciesToExportIds.length === 0) {
      this.messageHandlerService.generateToastMessage("error", "", `Please select agencies to generate the report`);
      return;
    }
    let requestPayload = {
      agencyIds: this.getAllowedAgencyIds(agenciesToExportIds),
      searchByDate: this.dateRangeToExportAgencyData,
    };

    this.getUserStatusDataToExport(requestPayload);
  }

  onExportUserActivationReport() {
    let agenciesToExportIds = this.utilService.getSelectedAgenciesIds(this.selectedAgenciesToExport);
    if (agenciesToExportIds && agenciesToExportIds.length === 0) {
      this.messageHandlerService.generateToastMessage("error", "", `Please select agencies to generate the report`);
      return;
    }
    let requestPayload = {
      agencyIds: this.getAllowedAgencyIds(agenciesToExportIds),
      searchByDate: this.dateRangeToExportAgencyData,
    };

    this.agencyService.getUserActivationReport(requestPayload).subscribe((res: any) => {
      if (res.success && res?.content) {
        const link = document.createElement("a");
        link.href = res.content.fileName;
        link.download = res.content.fileName;
        link.click();
        this.messageHandlerService.generateToastMessage("success", "", FILE_DOWNLOAD_SUCCESS);
      } else {
        this.messageHandlerService.generateToastMessage("error", "", "Error Downloading File: " + res.message);
      }
    });
  }

  onExportClinicianSuggestionCalculationReport() {
    let agenciesToExportIds = this.utilService.getSelectedAgenciesIds(this.selectedAgenciesToExport);
    if (agenciesToExportIds && agenciesToExportIds.length === 0) {
      this.messageHandlerService.generateToastMessage("error", "", `Please select agencies to generate the report`);
      return;
    }
    let requestPayload = {
      agencyIds: this.getAllowedAgencyIds(agenciesToExportIds),
      searchByDate: this.dateRangeToExportAgencyData,
    };

    this.agencyService.getClinicianSuggestionCalculateReportData(requestPayload).subscribe((res: any) => {
      if (res.success) {
        this.messageHandlerService.generateToastMessage("success", "", res.message);
      } else {
        this.messageHandlerService.generateToastMessage("error", "", "Error Downloading File: " + res.message);
      }
    });
  }

  getUserStatusDataToExport(requestPayload) {
    this.agencyService.getUserStatusDataToExport(requestPayload).subscribe(
      (res: any) => {
        if (res && res?.success) {
          let dataToExport = this.generateExcelReportDataForDeletedAndInactiveUsers(res?.content?.users);
          this.exportExcelReportForUserStatus(dataToExport);
        } else {
          this.messageHandlerService.generateToastMessage("error", "", `An error occurred`);
        }
      },
      (error) => {
        this.messageHandlerService.generateToastMessage("error", "", `An error occurred ${error}`);
      },
    );
  }

  generateExcelReportDataForDeletedAndInactiveUsers(data) {
    let dataToExport = [];
    if (data) {
      for (let e of data) {
        const name = e?.firstName + " " + e?.lastName;
        const status = e?.deleted ? "Deleted" : e?.status === "Inactive" ? "Inactive" : "";
        let row = [
          name,
          e?.email ?? "",
          e?.agency_roles && e?.agency_roles.length ? e?.agency_roles[0]?.agency?.agencyName ?? "" : "",
          e?.agency_roles && e?.agency_roles.length ? RoleNames[e?.agency_roles[0]?.roles[0]] ?? "" : "",
          status,
          e?.deletedAt ?? "",
        ];
        dataToExport.push(row);
      }
    }
    return dataToExport;
  }

  exportExcelReportForUserStatus(dataToExport) {
    const companyName = this.getCompanyNameForReport();

    // creating workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(USER_STATUS_REPORT_FILE_TITLE);

    //adding filter dates row
    this.addFilterDatesRow(worksheet, "deleted");

    //adding header row
    this.addHeaderRowForUserStatus(worksheet);

    //adding data row
    worksheet.addRows(dataToExport);

    //writing data to excel file
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(
        blob,
        (this.selectedAgenciesToExport.length === 1
          ? `${this.selectedAgenciesToExport[0].name}-${USER_STATUS_REPORT_FILE_NAME}`
          : companyName
          ? `${companyName}-${USER_STATUS_REPORT_FILE_NAME}`
          : USER_STATUS_REPORT_FILE_NAME) +
          this.datePipe.transform(new Date(), dateFormatStartWithYearWithOutDashes) +
          EXCEL_EXTENSION,
      );
    });
  }

  getExcelReportHeadersForUserStatus() {
    return [
      { key: "User Name", header: "FirstName", width: 30 },
      { key: "Email", header: "Email", width: 30 },
      { key: "Agency", header: "Agency", width: 20 },
      { key: "Role", header: "DefaultRole", width: 30 },
      { key: "Status", header: "status", width: 30 },
      { key: "Date Action Occurred", header: "deletedAt", width: 20 },
    ];
  }

  onGenerateReportSelect(event) {
    if (event.value == "Users & Devices") {
      this.onExportUserInfo();
    } else if (event.value == "Errors & Warnings") {
      this.onExportData();
    } else if (event.value == "User Status") {
      this.onExportUserStatusReport();
    } else if(event.value == "User Activation"){
      this.onExportUserActivationReport();
    } else if (event.value == CLINICIAN_CHANGE_REPORT_NAME) {
      this.onExportUserActivityReport();
    }else if(event.value == CLINICIAN_SUGGESTION_CALCULATION_REPORT_NAME){
      this.onExportClinicianSuggestionCalculationReport();
    }

    this.reportDropdown.updateSelectedOption("");
  }

  onExportUserActivityReport() {
    let agenciesToExportIds = this.utilService.getSelectedAgenciesIds(this.selectedAgenciesToExport);
    if (agenciesToExportIds && agenciesToExportIds.length === 0) {
      this.messageHandlerService.generateToastMessage("error", "", `Please select agencies to generate the report`);
      return;
    }
    let requestPayload = {
      agencyIds: this.getAllowedAgencyIds(agenciesToExportIds),
      searchByDate: this.dateRangeToExportAgencyData,
    };

    this.agencyService.getClinicianChangeReportData(requestPayload).subscribe((res: any) => {
      if (res.success) {
        const link = document.createElement("a");
        link.href = res.content.fileName;
        link.download = res.content.fileName;
        link.click();
        this.messageHandlerService.generateToastMessage("success", "", FILE_DOWNLOAD_SUCCESS);
      } else {
        this.messageHandlerService.generateToastMessage("error", "", "Error Downloading File: " + res.message);
      }
    });
  }
}
