import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {countries} from 'src/app/models/country-data';
import {eSockStatus} from 'src/app/models/patientSubmissions';
import {ESockSubmission} from 'src/app/models/patient';
import {MessageHandlerService} from 'src/app/services/message-handler.service';
import {PatientService} from 'src/app/services/patient/patient.service';
import {ProgressBarService} from 'src/app/services/progress-bar.service';
import {ConfirmationService} from 'primeng/api';
import { DatePipe } from '@angular/common';
import {
  adminRoles,
  dateAndTimeFormat,
  DeletedFileType,
  DOB_DATE_FORMAT, FileTypeHistory,
  NewFileUploadType,
  PHONE_NUMBER_LENGTH,
  PHONE_NUMBER_REGEX,
  RoleID,
  FILE_NOT_OPENED
} from 'src/app/common/Constants';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-esoc-upload',
  templateUrl: './esoc-upload.component.html',
  styleUrls: ['./esoc-upload.component.scss']
})
export class EsocUploadComponent implements OnInit {
  fileNotOpened = FILE_NOT_OPENED
  eSockSubmissionForm: ESockSubmission;
  showeSockDragAndDrop: boolean = true;
  eSockFileName: string;
  showUpdateAndDeleteEsock: boolean = false;
  fileLocation: string;
  isPreferredContactSelected: boolean = false;
  dobDateFormat = DOB_DATE_FORMAT;
  isNewFileUploaded: boolean = false;
  isFileUploadComplete: boolean = true;
  isExistingFileDeleted: boolean = false;
  hidePatientName: boolean = false;
  patientName: string;
  patientId: string;
  MRN: string;
  isValidPhoneNumber: boolean = true;
  eSockFiles: any;
  fileTypeHistory = FileTypeHistory;
  newFileUploadType = NewFileUploadType;
  dateAndTimeFormat = dateAndTimeFormat;
  @Input() eSOCUploadDisplay: boolean = false;
  @Input() patientSubmission?: any;
  @Input() userId: string;
  @Input() currentUserRole: string;
  @Input() eSOCUploadType: string;
  @Input() eSOCDeleteType: string;
  @Output() eSocPopUpClose = new EventEmitter<any>();
  @Output() eSockUploadListingRefresh = new EventEmitter<any>()
  showNumberOfRows = 3;
  isChecked: boolean = false;
  uploadedFile = null;
  value = 0;
  progressBar = false;
  fileTypes = [ {
  label: 'ePH File', value: 'file'},
    {label: 'piReport File', value: 'piReportFile'},
    {label: 'other File', value: 'otherFile'},]
  companyAdmin = RoleID.companyAdmin
  showDobInvalidError: boolean = false;
  hideReplaceEdockButton: boolean = false;
  protected readonly countries = countries;

  constructor(
    private readonly progressBarService: ProgressBarService,
    private patientService: PatientService,
    private messageHandlerService: MessageHandlerService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe,
    private utilService: UtilService,
  ){
    this.eSockSubmissionForm = {
      eSockFile: '',
      email: {email: '' , preferred: true},
      phoneNumber: {countryCode: countries[0].dial_code, number: '', preferred: false},
      dob: '',
      isChecked: false,
    }
  }

  get slicedItems() {
    return this.eSockFiles.slice(0, this.showNumberOfRows);
  }

  ngOnInit(): void {
    this.openSOCUploadPopup();
    this.changeCheckboxValue();
    this.isEmailMandatory();
    this.hideFromDataRoles();
    this.eSockFiles = this.patientSubmission?.patientEsocData?.mergeFiles?.slice(0, 6);
  }

  openSOCUploadPopup() {
    let eSoc = this.patientSubmission?.patientEsocData;
    let dob = this.patientSubmission?.patientEsocData?.patient?.dob;
    const firstName = this.patientSubmission?.patientEsocData?.patient?.firstName;
    const lastName = this.patientSubmission?.patientEsocData?.patient?.lastName;
    this.patientName = (firstName ? firstName : '')  + ' ' + (lastName ? lastName : '');
    this.MRN = this.patientSubmission?.patientEsocData?.patient?.mrn;
    this.patientId = this.patientSubmission?.patientEsocData?.patient?._id ? this.patientSubmission?.patientEsocData?.patient?._id : this.patientSubmission?.patientId;

    if (eSoc?.[this.eSOCUploadType] && eSoc?.[this.eSOCUploadType][0] !== undefined) {
      this.eSockFileName = eSoc?.[this.eSOCUploadType][0]. fileOriginalName || eSoc?.[this.eSOCUploadType][0]?.fileName;
      this.showUpdateAndDeleteEsock = true;
      this.showeSockDragAndDrop = false;
      this.hideReplaceEdockButton = true;

      this.eSockSubmissionForm = {
        isChecked: this.patientSubmission?.patientEsocData?.patient?.contactInfo?.consent,
        eSockFile: eSoc?.[this.eSOCUploadType][0]?.fileName,
        email: {
          email: this.patientSubmission?.patientEsocData?.eSocEmailPhonePreference?.email?.email
          , preferred: this.patientSubmission?.patientEsocData?.eSocEmailPhonePreference?.email?.preferred
        },
        phoneNumber: {
          countryCode: this.getCountryCode(), number: this.getPhoneNumber()
          , preferred: this.patientSubmission?.patientEsocData?.eSocEmailPhonePreference?.phone?.preferred
        },
        dob
      }
    }
    else {
      this.eSockSubmissionForm = {
        eSockFile: '',
        email: {
          email: this.patientSubmission?.patientEsocData?.eSocEmailPhonePreference?.email?.email,
          preferred: this.patientSubmission?.patientEsocData?.eSocEmailPhonePreference?.email?.preferred
        },
        phoneNumber: {
          countryCode: this.patientSubmission?.patientEsocData?.patient?.contactInfo ? this.getCountryCode() : countries[0].dial_code,
          number: this.patientSubmission?.patientEsocData?.patient?.contactInfo ? this.getPhoneNumber() : '',
          preferred: this.patientSubmission?.patientEsocData?.eSocEmailPhonePreference?.phone?.preferred
        },
        dob,
        isChecked: this.patientSubmission?.patientEsocData?.patient?.contactInfo?.consent,
      }
      this.eSockFileName = ''
      this.showUpdateAndDeleteEsock = false;
      this.showeSockDragAndDrop = true;
    }
    this.eSOCUploadDisplay = true;
  }

  getCountryCode()
  {
    let phoneNumber = this.patientSubmission?.patientEsocData?.patient?.contactInfo?.phone;
    let differnce = phoneNumber?.length-PHONE_NUMBER_LENGTH;
    phoneNumber = phoneNumber?.slice(0, differnce);
    return phoneNumber;
  }

  getPhoneNumber()
  {
    let phoneNumber = this.patientSubmission?.patientEsocData?.patient?.contactInfo?.phone;
    let differnce = phoneNumber?.length-PHONE_NUMBER_LENGTH;
    phoneNumber = phoneNumber?.slice(differnce);
    return phoneNumber;
  }

  eSOCUploadPopupClose() {
    this.eSOCUploadDisplay = false;
    this.eSocPopUpClose.emit();
  }

  async handleFileUpload(event: any) {
    this.progressBar = true;
    this.isFileUploadComplete = false;
    const file = event.target.files[0];
    this.eSockFileName = file.name;
    const formData = new FormData();
    formData.append('file', file);
    this.showeSockDragAndDrop = false;
    this.showUpdateAndDeleteEsock = true;
    this.progressBarService.setProgressBarVisibility(true);

    let interval = setInterval(() => {
      if (this.value < 100)
        this.value = this.value + Math.floor(Math.random() * 10) + 1;

      if (this.value >= 73 && this.value < 100) {
        this.value = 73;
      }  else if (this.value >= 100) {
        clearInterval(interval);
      }

    }, 400);

      this.patientService.uploadFile(formData).subscribe(res => {
        this.value = 100;
        if (res?.message?.Location) {
          this.isFileUploadComplete = true;
          this.isNewFileUploaded = true;
          this.fileLocation = res?.message?.Location;
          this.uploadedFile = {fileName: res?.message?.key,fileOriginalName:this.eSockFileName, fileLocation: this.fileLocation, createdAt: new Date()};
        } else {
          this.uploadedFile = null;
          this.showeSockDragAndDrop = true;
          this.showUpdateAndDeleteEsock = false;
          this.fileLocation = null;
          this.eSockSubmissionForm.eSockFile = '';
          this.messageHandlerService.generateToastMessage('error', '', 'Unable to upload file');
        }
        this.progressBarService.setProgressBarVisibility(false);

        setTimeout(() => {
          this.progressBar = false;
          this.value = 0;
        }, 1000);
      });
  }

  expandCollapseHistory() {
    this.showNumberOfRows = this.showNumberOfRows == 6 ? 3 : 6;
  }

  async deleteEsockFile() {
    this.progressBar = false;
    this.value = 0;

    await this.patientService.deleteESocFile({
      fileObject: this.uploadedFile ? {fileName: this.uploadedFile?.fileOriginalName , fileLocation: this.uploadedFile?.fileLocation} :  this.patientSubmission?.patientEsocData?.[this.eSOCDeleteType] && this.patientSubmission?.patientEsocData?.[this.eSOCDeleteType].length ?
        this.patientSubmission?.patientEsocData?.[this.eSOCDeleteType][0] : {},
        patientEsocRecordId: this.patientSubmission._id,
        type: this.eSOCDeleteType,
    }).subscribe(res => {
      if (res) {
        this.uploadedFile = null;
        this.showeSockDragAndDrop = true;
        this.showUpdateAndDeleteEsock = false;
        if(!this.isNewFileUploaded){
          let deletedFile = this.patientSubmission?.patientEsocData?.[this.eSOCDeleteType][0];
          this.eSockFiles = this.deleteFileFromArray(this.patientSubmission?.patientEsocData?.mergeFiles, deletedFile);
          this.hideReplaceEdockButtonOnDelete(this.eSockFiles);
          this.isExistingFileDeleted = true;
          this.addeSocToSubmission(false);
        }
        this.fileLocation = null;
        this.eSockSubmissionForm.eSockFile = '';
        this.messageHandlerService.generateToastMessage('success', '', 'File deleted successfully');
      }
    },(error) => {
      this.messageHandlerService.generateToastMessage('error', '', 'An error occurred while removing eSoc file.');
    });
  }

  deleteFileFromArray(files, deletedFile){
    let index = files.findIndex(obj => {
      return obj.fileLocation === deletedFile.fileLocation;
    })
    delete files[index];
    files.splice(index, 1);
    return files;
  }

  hideReplaceEdockButtonOnDelete(files){
    let result = files.some(obj => {
      return obj.fileType == DeletedFileType[this.eSOCUploadType]
    })
    if(result){
      this.hideReplaceEdockButton = true;
    }
    else{
      this.hideReplaceEdockButton = false;
    }
  }

  deleteEdocFileConfirmation() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the document?',
      header: 'Delete eSOC',
      acceptButtonStyleClass:'btn-delete',
      icon: 'pi pi-trash',
      accept: () => {
        this.deleteEsockFile();
      },
      reject: (type) => {
      }
    });
  }


  changeCheckboxValue()
  {
    // if(!this.eSockSubmissionForm.email.preferred && !this.eSockSubmissionForm.phoneNumber.preferred)
    // {
    //   this.isPreferredContactSelected = true;
    //    return;
    // }
    // else{
    //    this.isPreferredContactSelected = false;
    // }
    if(this.eSockSubmissionForm?.email?.preferred && this.eSockSubmissionForm?.phoneNumber?.preferred){
      this.isPreferredContactSelected = true;
    }else if(!this.eSockSubmissionForm?.email?.preferred && !this.eSockSubmissionForm?.phoneNumber?.preferred){
      this.isPreferredContactSelected = false;
    }else if (!this.eSockSubmissionForm?.email?.preferred && this.eSockSubmissionForm?.phoneNumber?.preferred){
      this.isPreferredContactSelected = true;
    }else if (this.eSockSubmissionForm?.email?.preferred && !this.eSockSubmissionForm?.phoneNumber?.preferred){
      this.isPreferredContactSelected = false;
    }
    this.isEmailMandatory();
    this.isPhoneNumberValid();
  }

  isEmailMandatory(){
    return this.eSockSubmissionForm?.email?.preferred;
  }

  async addeSocToSubmission(eSockPopupClose?: boolean, alsoSend = false) {
    const phoneNumber = this.eSockSubmissionForm?.phoneNumber?.number;
    const email = this.eSockSubmissionForm?.email?.email;
    let request: any = {
      // file: this.patientSubmission?.patientEsocData?.file && this.patientSubmission?.patientEsocData?.file.length ? [this.patientSubmission?.patientEsocData?.file[0]] : [],
      submittedOn: new Date(),
      status: eSockStatus.APPROVED,
      // submittedBy: this.userId,
      consent: this.eSockSubmissionForm?.isChecked,
      email: email ? { email: this.eSockSubmissionForm.email.email, preferred: this.eSockSubmissionForm.email.preferred,
      sent: this.patientSubmission?.patientEsocData?.eSocEmailPhonePreference?.email?.email?.sent ? this.patientSubmission?.patientEsocData?.eSocEmailPhonePreference?.email?.email?.sent : false } : null,
      phone: phoneNumber ? { countryCode: this.eSockSubmissionForm?.phoneNumber?.countryCode, number: this.eSockSubmissionForm?.phoneNumber?.number, preferred: this.eSockSubmissionForm?.phoneNumber?.preferred,
      sent: this.patientSubmission?.patientEsocData?.eSocEmailPhonePreference?.phone?.sent ? this.patientSubmission?.patientEsocData?.eSocEmailPhonePreference?.phone?.sent : false} : null,
    }

    if (this.uploadedFile)
      request[this.eSOCUploadType] = [this.uploadedFile];

    //this.eSockSubmissionForm.dob = new Date(this.eSockSubmissionForm?.dob).setDate(new Date(this.eSockSubmissionForm?.dob).getDate() + 1)
    this.progressBarService.setProgressBarVisibility(true);
    /*this.subscriptions.push(*/await this.patientService.updatePatientSubmission({
      // id: this.patientSubmission._id,
      patientId: this.patientSubmission?.patientId,
      eSoc: request,
      dob: this.formatDate(),
    }).subscribe(res => {
      if (res?.success && res?.content) {
        if(eSockPopupClose)
        {
          this.messageHandlerService.generateToastMessage('success', '', 'Record updated successfully');
          this.eSOCUploadPopupClose();
          this.eSockSubmissionForm = {
            eSockFile: '',
            email: {email: '' , preferred: true},
            phoneNumber: {countryCode: countries[0].dial_code, number: '', preferred: false},
            dob: ''
          }
        }
        this.openeSockDragAndDropAgain();

        if (alsoSend)
          this.eSockUploadListingRefresh.emit({type: this.eSOCUploadType});
        else
          this.eSockUploadListingRefresh.emit();
      } else {
        this.messageHandlerService.generateToastMessage('error', '', 'An error occurred while updating eSoc.');
      }
      this.progressBarService.setProgressBarVisibility(false);
    })/*)*/;
  }

  openeSockDragAndDropAgain() {
    this.progressBar = false;
    this.value = 0;
    this.eSockSubmissionForm.eSockFile = '';
    this.fileLocation = ''
    this.showUpdateAndDeleteEsock = false;
    this.showeSockDragAndDrop = true;
  }

  isPhoneNumberValid(event?) {
    if(this.eSockSubmissionForm?.phoneNumber?.preferred){
      if(this.eSockSubmissionForm?.phoneNumber?.number?.length > 0){
        let phone = event ? event : this.eSockSubmissionForm?.phoneNumber?.number
        if (phone?.includes('-')) {
          phone = phone?.split('-').join("")
        }
        this.isValidPhoneNumber =  new RegExp(PHONE_NUMBER_REGEX).test(phone);
      }else{
        this.isValidPhoneNumber = false;
      }
    }else{
      this.isValidPhoneNumber = true;
    }
    return this.isValidPhoneNumber;

  }

  updateEsockFile() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to upload a new document, as it will override the previous one?',
        header: 'Update eSOC',
        icon: 'pi pi-upload',
        accept: () => {
          this.openeSockDragAndDropAgain();
        },
        reject: (type) => {
        }
    });
  }

  formatDate(){
    let dob = this.eSockSubmissionForm?.dob ? this.eSockSubmissionForm?.dob : this.patientSubmission?.patientEsocData
      ?.patient?.dob;
    let formattedDate = this.utilService.toLocal(dob);
    return this.datePipe.transform(formattedDate, 'MM/dd/yyyy');
  }

    hideFromDataRoles() {
    this.hidePatientName =  adminRoles.includes(this.currentUserRole);
  }

  isDobValid(){
    if(this.eSockSubmissionForm.dob){
      let transformedDate =  this.datePipe.transform(this.eSockSubmissionForm.dob, 'MM/dd/yyyy');
      let currentDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy')
      if(new Date(transformedDate) > new Date(currentDate)){
        this.showDobInvalidError = true;
      }
      else{
        this.showDobInvalidError = false;
      }
    }
  }
}
