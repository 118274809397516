import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersComponent} from './ioh-users/users.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {SidebarModule} from 'primeng/sidebar';
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextModule} from 'primeng/inputtext';
import {DashboardRoutingModule} from '../dashboard/dashboard-routing.module';
import {DropdownModule} from "primeng/dropdown";
import {MultiSelectModule} from "primeng/multiselect";
import {TableModule} from "primeng/table";
import {LoaderWrapperModule} from "../loader-wrapper/loader-wrapper.module";
import {TooltipModule} from 'primeng/tooltip';
import {RadioButtonModule} from 'primeng/radiobutton';
import {FileUploadModule} from "primeng/fileupload";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ConfirmationService} from "primeng/api";
import {BrowserModule} from "@angular/platform-browser";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {InputMaskModule} from "primeng/inputmask";
import {AutoCompleteModule} from "primeng/autocomplete";
import {PaginatorModule} from 'primeng/paginator';
import {NonIohUsersComponent} from './non-ioh-users/non-ioh-users.component';
import {SharedModule} from "../shared/shared.module";
import {SupportModule} from "../support/support.module";
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [
    UsersComponent,
    NonIohUsersComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    SidebarModule,
    CheckboxModule,
    InputTextModule,
    DashboardRoutingModule,
    DropdownModule,
    MultiSelectModule,
    TableModule,
    LoaderWrapperModule,
    TooltipModule,
    RadioButtonModule,
    FileUploadModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    BrowserModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    InputMaskModule,
    AutoCompleteModule,
    PaginatorModule,
    SharedModule,
    SupportModule,
    DialogModule
  ],
  exports: [
    UsersComponent
  ],
  providers: [ConfirmationService]
})
export class UsersModule { }
