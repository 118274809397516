import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginModule} from './login/login.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {ErrorInterceptor} from "./helper/error.interceptor";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ToastModule} from "primeng/toast";
import {MessageService} from "primeng/api";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserDataManager} from "./user/UserDataManager";
import {DropdownModule} from "primeng/dropdown";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {RoleInterceptor} from "./helper/role.interceptor";
import {AutoCompleteModule} from "primeng/autocomplete";
import {SidenavService} from "./core/sidebar/sidenav.service";
import {DatePipe} from "@angular/common";
import {SupportModule} from "./support/support.module";
import {EsocVerificationModule} from "src/app/esoc-verification/esoc-verification.modules";
import {ChipsModule} from "primeng/chips";
import { IohStatusComponent } from './ioh-status/ioh-status.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import {FileUploadModule} from "primeng/fileupload";
import { StoreModule } from '@ngrx/store';
import {reducers} from "./store/reducers";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";


@NgModule({
  declarations: [
    AppComponent,
    IohStatusComponent,
    FileUploadComponent,

  ],
  imports: [
    BrowserModule,
    LoginModule,
    DashboardModule,
    AppRoutingModule,
    HttpClientModule,
    ToastModule,
    BrowserAnimationsModule,
    DropdownModule,
    FormsModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    SupportModule,
    EsocVerificationModule,
    ReactiveFormsModule,
    ChipsModule,
    FileUploadModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  ],
  providers: [MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RoleInterceptor, multi: true},
    SidenavService,
    UserDataManager,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
