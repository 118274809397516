import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserService} from "../../services/user.service";
import {
  //bulkUploadTemplate,
  bulkUploadTemplateValues,
  defaultSelectedIOHUserRows,
  IOHUserRows,
  User, UserFilterDropdown, UserStatus,
  userStatusValues
} from "../../models/user";
import {MessageHandlerService} from "../../services/message-handler.service";
import {UserDataManager} from "../../user/UserDataManager";
import {Agency, IOHRoleFilterOptions, IOHRoles, RoleEnum, status, statusValues} from "../../models/agency";
import {AgencyService} from "../../services/agency/agency.service";
import { CompanyService } from 'src/app/services/company/company.service';
import {ProgressBarService} from "../../services/progress-bar.service";
import {
  adminRoles,
  BULK_USER_UPLOAD,
  BULK_USER_UPLOAD_ALLOWED_FORMAT,
  BULK_USER_UPLOAD_FAILED_MESSAGE,
  BULK_USER_UPLOAD_FILE_FORMAT_ERROR,
  dateAndTimeFormat,
  dateFormat,
  DEFAULT_SEARCH_MIN_CHARACTER, EMAIL_ALREADY_REGISTERED_USER, IOH_ADMIN,
  NEW_PASSWORD_REQUEST_SEND,
  RoleID,
  rowsPerPageOptions,
  TEMP_PASSWORD_EXPIRED,
  USER_ACCOUNT_ALREADY_EXIST,
  USER_VERIFIED
} from "../../common/Constants";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ConfirmationService, SelectItem} from "primeng/api";
import {countries} from "../../models/country-data";
import {Subscription} from "rxjs";
import {PasswordStrengthValidator} from "../../helper/confirmed.validator";
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from "@angular/platform-browser";
import {HttpParams} from '@angular/common/http';
import {FileUpload} from "primeng/fileupload";
import {Paginator} from 'primeng/paginator';
import {UtilService} from 'src/app/services/util/util.service';
import {LocalStorageService} from "../../services/local-storage.service";
import {DatePipe} from "@angular/common";
import {isMultiAgenciesUserEnabled} from "../../services/util/helper.function";
import { Company } from 'src/app/models/company';
import {Dropdown} from "primeng/dropdown";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  subscribers: Subscription[] = [];
  users: User[] = [];
  currentUser: User;
  currentAgencyRole: {agency: string, role: string};
  dateFormat = dateFormat;
  dateAndTimeFormat = dateAndTimeFormat;
  AGENCY_ADMINISTRATOR = RoleID.AgencyAdmin;
  COMPANY_ADMIN = RoleID.companyAdmin;
  totalUsers: number = 0;
  display = false;
  globalRoles: string;
  roles = IOHRoles;
  statusValues = statusValues;
  agencies: SelectItem[] = [];
  companies: SelectItem[] = [];
  agenciesFilter: SelectItem[] = [];
  companiesPayload: Company[] = [];
  rolesFilterOptions: SelectItem[] = IOHRoleFilterOptions;
  selectedAgencies: Agency[] = [];
  numberOfAgencies: number = 0;
  searchUser: string = '';
  currentImage: any;
  statusEnum = status;
  newUserForm: FormGroup;
  countries = countries;
  userDropDownOptions: SelectItem[];
  selectedItem: any;
  selectedIndex: number;
  showEditForm: boolean = false;
  filteredAgencies: any[];
  filteredCompanies: any[];
  agencyDetails: Agency[];
  pageNo: number = 0;
  noOfUsersPerRow: number = 10 ;
  rowsPerPageOptions: number[] = rowsPerPageOptions;
  isChangingPage: boolean = false;
  SUPER_ADMIN = RoleID.Admin;
  IOH_ADMIN = RoleID.iohAdmin;
  selectedAgency: string = '';
  selectedRoles: string = '';
  selectedRows: any[] = defaultSelectedIOHUserRows;
  visibleRows: any[] = IOHUserRows;
  userFilter: any[] = UserFilterDropdown;
  userFilterSelectedRows: any[] = [];
  userStatusFilterOptions: SelectItem[] = userStatusValues;
  selectedUserStatus;
  userStatusFilter: string = "";
  roleEnum = RoleEnum;
  emailType = '';
  newUserAgencies: SelectItem[] = [];
  defaultSearchMinCharacter = DEFAULT_SEARCH_MIN_CHARACTER;
  disableMultiAgencyFilter: boolean = true;
  bulkUploadTemplateOptions = bulkUploadTemplateValues;
  bulkUploadTemplate;
  componentLocalStorageKey = 'iohUserState';
  showUserCreationSuccessPopup: boolean = false;
  loading: boolean = false;
  @ViewChild('paginator') paginator: Paginator
  @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload;
  @ViewChild('bulkUploadUsers', { static: false }) bulkUploadUsers: FileUpload;
  @ViewChild('downloadTemplateDropdown') dropdown: Dropdown;
  mfaEnabled: boolean = true;

  constructor(private userService: UserService,
              private messageHandlerService: MessageHandlerService,
              public userDataManager: UserDataManager,
              private agencyService: AgencyService,
              private companyService: CompanyService,
              private utilService: UtilService,
              private readonly progressBarService: ProgressBarService,
              private formBuilder: FormBuilder,
              private confirmationService: ConfirmationService,
              private matIconRegistry: MatIconRegistry,
              private localStorageService: LocalStorageService,
              private domSanitizer: DomSanitizer,
              private datePipe: DatePipe,)
  {
    this.userDropDownOptions = [{label: 'Edit', value: 'Edit'}, {label: 'Delete', value: 'Delete'}, {label: 'Inactive', value: 'Inactive'}];
    this.matIconRegistry.addSvgIcon("status", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/active-inactive.svg"));
    this.matIconRegistry.addSvgIcon("delete", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/delete-item.svg"));
  }

  get role(): FormArray {
    return this.newUserForm.get('agency_roles') as FormArray
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.getDataFromLocalStorage();
    this.getCurrentAgencyRole();
    this.createUsersForm();
    this.getSelectedRows();
    if (this.currentUser?.is_IOH_admin || this.currentUser?.is_super_admin) {
      this.getCompanyListing()
    } else if (this.currentUser?.is_company_admin) {
      this.getCompanyListing(this.currentUser.companyId)
    }
  }

  getDataFromLocalStorage() {
    const iohUserState = JSON.parse(this.localStorageService.get(this.componentLocalStorageKey));
    this.selectedUserStatus = iohUserState?.selectedUserStatus ? iohUserState?.selectedUserStatus : '';
    this.userStatusFilter = this.selectedUserStatus;
  }

  onAgencySelect() {
    const selectedAgencies = this.newUserForm.value?.agency_roles;
    const selectedAgenciesSet = new Set(selectedAgencies.map(item => item.agency));
    this.agencies = this.agencies.map(item => {
      if (selectedAgenciesSet.has(item.value)) {
        return { ...item, disabled: true };
      } else {
        return { ...item, disabled: false };
      }
    });
  }

  getSelectedRows() {
    if(!this.localStorageService.get('userSettings' + this.currentUser?.aws_cognito_id)) {
      this.selectedRows = defaultSelectedIOHUserRows;
      this.localStorageService.set( 'userSettings' + this.currentUser?.aws_cognito_id, JSON.stringify({selectedIOHUserRows: this.selectedRows}));
    } else {
      this.selectedRows = JSON.parse(this.localStorageService.get('userSettings' + this.currentUser?.aws_cognito_id))?.selectedIOHUserRows
        ? JSON.parse(this.localStorageService.get('userSettings' + this.currentUser?.aws_cognito_id))?.selectedIOHUserRows : defaultSelectedIOHUserRows;
    }
  }

  ngOnDestroy(): void {
    if(this.subscribers.length > 0) {
      this.subscribers.forEach((sub) => {
        sub.unsubscribe();
      })
    }
  }

  getCurrentAgencyRole() {
    this.subscribers.push(this.userDataManager.activeAgencyRole$.subscribe((currentRole) => {
      this.currentAgencyRole = currentRole;
      this.getAgencyListing();
      this.addCompanyAdminFilter();
      this.getUsers();
    }));
  }

  addCompanyAdminFilter() {
    const isCompanyAdmin = this.rolesFilterOptions.findIndex(rolesFilterOption => rolesFilterOption.value == this.COMPANY_ADMIN);

    if(this.currentAgencyRole.role == this.IOH_ADMIN || this.currentAgencyRole.role == this.SUPER_ADMIN) {
      if(isCompanyAdmin == -1) {
        const companyOption = {
          value: this.COMPANY_ADMIN, label: 'Company Admin',
        }

        this.rolesFilterOptions.splice(1, 0, companyOption);
      }
    } else if(isCompanyAdmin != -1) {
      this.rolesFilterOptions.splice(1, 1);
    }
  }

  async customSort(event) {
    this.users = [];
    this.totalUsers = 0;

    const iohUsersSorting = {
      order: event?.sortOrder,
      orderBy: event?.sortField,
    }

    this.localStorageService.set('iohUsersSorting', JSON.stringify(iohUsersSorting));

    await this.getUsers(event?.sortOrder, event?.sortField);
  }

  async getCurrentUser() {
    this.subscribers.push(await this.userDataManager.user$.subscribe((user) => {
      if(user) {
        this.currentUser = user;
      }
    }));
  }

  preFetchCompanyId() {
    if (this.currentUser?.is_company_admin) {
      return this.currentUser.companyId
    }

    if ((this.currentUser?.is_IOH_admin || this.currentUser?.is_super_admin) && this.newUserForm.value.companyId && this.newUserForm.value.companyId && 'value' in this.newUserForm.value.companyId) {
      return this.newUserForm.value.companyId.value
    }
  }

  createUsersForm() {
    this.newUserForm = this.formBuilder.group({
      is_super_admin: false,
      is_IOH_admin: this.currentAgencyRole?.role === RoleID.iohAdmin,
      is_company_admin: false,
      is_ioh_user: true,
      picture: [''],
      firstName: ['', Validators.required],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      phone: this.formBuilder.group({
        number: ['', Validators.required],
        countryCode: [countries[0].dial_code],
      }),
      status: ['Active'],
      agency_roles: this.formBuilder.array([]),
      defaultRole: [''],
      email: ['', [Validators.required, Validators.email]],
      userSettings: this.formBuilder.group({
        logging: true,
        loggingView: true,
        isMultiAgenciesAllowed: false,
        mfa: true,
      }),
      isIOHHelp: true,
      // companyId: ['']
    });

    this.iohUserChangeSubscriber()
  }

  getIohUserValidator(): any{
    let iohUser = this.newUserForm?.get('is_ioh_user')?.value;
    let validators = iohUser ? [Validators.required] : [];
    return validators;
  }

  iohUserChangeSubscriber()
  {
    this.newUserForm.get('is_ioh_user').valueChanges.subscribe((obj) => {
      if(!obj){
        this.newUserForm.controls['defaultRole'].reset();
      }
      let defaultRole = this.newUserForm.get('defaultRole');
      defaultRole.setValidators(this.getIohUserValidator())
      defaultRole.updateValueAndValidity();
    })
  }

  createEditUsersForm() {
      this.newUserForm.patchValue({
      is_super_admin: this.users[this.selectedIndex].is_super_admin,
      is_IOH_admin: this.users[this.selectedIndex].is_IOH_admin,
      picture: this.users[this.selectedIndex].picture,
      firstName: this.users[this.selectedIndex].firstName,
      lastName: this.users[this.selectedIndex].lastName,
      phone: {
        countryCode: this.users[this.selectedIndex]?.phone?.countryCode,
        number: this.users[this.selectedIndex]?.phone?.number,
      },
      status: this.users[this.selectedIndex].status,
      defaultRole: this.users[this.selectedIndex].defaultRole,
      email: this.users[this.selectedIndex].email,
      userSettings: {
        logging:  this.users[this.selectedIndex]?.userSettings?.logging,
        loggingView:  this.users[this.selectedIndex]?.userSettings?.loggingView,
        isMultiAgenciesAllowed:  this.users[this.selectedIndex]?.userSettings?.isMultiAgenciesAllowed,
        mfa:  this.users[this.selectedIndex]?.userSettings?.mfa,
      },
      isIOHHelp: this.users[this.selectedIndex]?.isIOHHelp,
    });
    this.onIOHAndSuperAdminChange()
    this.iohUserChangeSubscriber();
  }

  onIohAdminChange() {
    if (this.newUserForm.value.is_IOH_admin) {
      this.newUserForm.get('is_super_admin').patchValue(false);
    }
  }

  prepareEditUserForm() {
    this.checkSelectedAgencyRoles();
    this.newUserForm = this.formBuilder.group({
      is_super_admin: false,
      is_IOH_admin: false,
      is_company_admin: false,
      is_ioh_user: true,
      picture: [''],
      firstName: ['', Validators.required],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      phone: this.formBuilder.group({
        number: ['', Validators.required],
        countryCode: [''],
      }),
      status: [''],
      agency_roles: this.formBuilder.array([]),
      defaultRole: [''],
      email: ['', [Validators.required, Validators.email]],
      userSettings: this.formBuilder.group({
        logging: true,
        loggingView: true,
        isMultiAgenciesAllowed: false,
        mfa: false,
      }),
      // companyId: [''],
      isIOHHelp: true,
    });
  }

  createAgency(): FormGroup {
    return this.formBuilder.group({
      agency: ['', Validators.required],
      roles: [[], Validators.required],
      defaultAgency: [this.newUserForm.get('is_ioh_user').value === false ? true : false],
    });
  }

  getAgencyListing(companyId?: string) {
    let params = new HttpParams();
    if ((this.currentAgencyRole.role == RoleID.Admin || this.currentAgencyRole.role == RoleID.iohAdmin) && !companyId) {
      params = undefined;
    } else if (this.currentAgencyRole.role == RoleID.companyAdmin) {
      params = params.append('companyId', `${this.currentUser.companyId}`)
    } else if (!companyId) {
      params = params.append('agency', this.currentAgencyRole.agency);
      params = params.append('role', this.currentAgencyRole.role);
    }

    if (companyId) {
      params = params.append('companyId', `${companyId}`)
    }

    this.subscribers.push(this.agencyService.getAgencies(params).subscribe(res => {
      if(res.success) {
        this.agencyDetails = res.content.data;
        this.agencies = res.content.data.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        });
        this.sortAgenciesByName();
        this.agenciesFilter = JSON.parse(JSON.stringify(this.agencies));
        this.agenciesFilter.unshift({ label: 'All', value: '' });
      }
    }));
  }
  sortAgenciesByName() {
    this.agencies.sort((a, b) => {
      let fa = a.label.toLowerCase(),
        fb = b.label.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }
  getCompanyListing(companyId?: string) {
    this.subscribers.push(this.companyService.getAllCompanies({ ...(companyId ? {companyId} : null) }).subscribe(res => {
      if (res.success) {
        const activeCompanies = res.content.data.filter(company => company.status == UserStatus.active);
        this.companies = activeCompanies.map(item => ({ label: item.name, value: item.id }))
        this.companiesPayload = res.content.data
      }
    }))
  }

  checkIfDefaultAgencySelected() {
    const agencyArray = this.newUserForm.value;
    if(agencyArray.agency_roles.length == 0 && (agencyArray.is_super_admin || agencyArray.is_IOH_admin || agencyArray.is_company_admin)) {
      return true;
    }

    const index = agencyArray.agency_roles.findIndex(item => item.defaultAgency);
    if(index > -1) {
      return true;
    }
    return false;
  }

  onIOHAndSuperAdminChange() {
    this.newUserForm.get('agency_roles').clearValidators();
    this.newUserForm.get('agency_roles').updateValueAndValidity();
    this.newUserForm.get('defaultRole').clearValidators();
    this.newUserForm.get('defaultRole').updateValueAndValidity();
    this.newUserForm.get('defaultRole').patchValue('');
    (this.newUserForm.get('agency_roles') as FormArray).clear();
  }

  superAdminChange() {
    if(this.newUserForm.value.is_super_admin) {
      this.newUserForm.get('is_company_admin').patchValue(false);
      this.newUserForm.get('is_IOH_admin').patchValue(false);
    }
  }

  canCreateUser() {
    return this.currentUser?.is_super_admin || this.currentUser?.is_IOH_admin || this.currentUser?.is_company_admin
  }

  getAllRoles(user): any {
    if(user.agency_roles && user.agency_roles.length) {
      let res = user.agency_roles.flatMap(role => role.roles);

      if (res.length) {
        res.sort();

        return Array.from(new Set(res));
      }
    }
  }

  createTooltip(users: User) {
    let res = `<b>Created On:</b> ${this.datePipe.transform(users.createdAt, dateAndTimeFormat)}` + '<br>'
    if (users?.creator)
      res = res + `<b>Created By:</b> ${users?.creator}` + '<br>'

    if(users?.platform)
      res = res + `<b>Platform:</b> ${users?.platform}` + '<br>'

    if(users?.desktopVersion)
      res = res + `<b>Desktop Version:</b> ${users?.desktopVersion}` + '<br>'

    if(users?.androidVersion)
      res = res + `<b>Android Version:</b> ${users?.androidVersion}` + '<br>'

    if(users?.lastLogin)
      res = res + `<b>Last Login:</b> ${this.datePipe.transform(users?.lastLogin, dateAndTimeFormat)}` + '<br>'

    return res;
  }

  searchUsers() {
    if(this.searchUser === undefined || this.searchUser === null || this.searchUser === ""){
      this.getUsers();
    } else if(this.searchUser && this.searchUser.length >= this.defaultSearchMinCharacter) {
      this.pageNo = 0;
      this.getUsers();
    }
  }

  getUsers(order: number = 0, orderBy: string = '') {
    try {
      this.progressBarService.setProgressBarVisibility(true);
      this.loading = true;
      const iohUsersSorting = JSON.parse(this.localStorageService.get('iohUsersSorting'));
      let request = {
        ...(this.currentUser.is_company_admin ? { companyId: this.currentUser.companyId } : null),
        search: this.searchUser ? this.searchUser : "",
        status: this.userStatusFilter,
        partOfAgency: this.adminRoles() ? this.selectedAgency : null,
        role: this.selectedRoles,
        //pagination: { pageNumber: this.userStatusFilter === '' ? this.pageNo : 0 , pageSize: this.noOfUsersPerRow},
        pagination: { pageNumber: this.pageNo, pageSize: this.noOfUsersPerRow },
        IOH: true,
        order: order ? order.toString() : iohUsersSorting?.order.toString(),
        orderBy: orderBy ? orderBy : iohUsersSorting?.orderBy,
        // usersFilter: this.userFilterSelectedRows
      }
      this.subscribers.push(this.userService.getUsersWithSearch(request).subscribe(res => {
        if(res?.success) {
          this.users = res?.content?.data;
          this.totalUsers = res?.content?.totalUsersCount;
          if(!this.isChangingPage)
          {
            this.changePage();
          }
        } else if(res) {
          this.messageHandlerService.generateToastMessage('error','Communication error:  ', res?.errorMessage);
        }
        this.progressBarService.setProgressBarVisibility(false);
        this.loading = false;
      }));
    }catch (e) {
      console.log(e);
      this.progressBarService.setProgressBarVisibility(false);
      this.loading = false;
      this.messageHandlerService.generateToastMessage('error','Communication error: ', e?.errorMessage);
    }
  }

  changeStatus(index: number) {
      let user: User = this.users[index];
      user.status = user.status === 'Active' ? 'Inactive' : 'Active';
      this.updateUserStatus(user);
  }

  updateUserStatus(user: User) {
    this.progressBarService.setProgressBarVisibility(true);
    this.subscribers.push(this.userService.updateUser(user).subscribe(res => {
      if(res.success) {
        this.getUsers();
        this.onSlideBarClose();
        this.messageHandlerService.generateToastMessage('success', '', 'User Updated Successfully');
        this.progressBarService.setProgressBarVisibility(false);
      } else {
        this.messageHandlerService.generateToastMessage('error', '', 'User Not Updated');
        this.progressBarService.setProgressBarVisibility(false);
      }
    }));
  }

  onAddUserClick() {
    this.showEditForm = false;
    this.display = true;
    this.selectedIndex = -1;
    this.createUsersForm();
  }

  onDropDownChange(index: number, selectedOption) {
    if (selectedOption === 'Edit') {
      this.selectedIndex = index;
      this.showEditForm = true;
      this.display = true;
      this.prepareEditUserForm();
      this.createEditUsersForm();
      this.getAgencyListing(this.preFetchCompanyId())
    } else if (selectedOption === 'Delete') {
      console.log(index);
      console.log(selectedOption);
      this.selectedIndex = index;
      this.onDeleteClick();
    }
  }

  onDeleteClick() {
    if (this.users[this.selectedIndex].aws_cognito_id == this.currentUser.aws_cognito_id) {
      this.messageHandlerService.generateToastMessage('error','', 'Cannot delete logged in user');
      return;
    }

    this.onSlideBarClose();
    this.confirmationService.confirm({
      message: 'Please confirm if you wanted to delete user.',
      acceptButtonStyleClass:'btn-delete',
      accept: () => {
        this.deleteUser();
      }
    });
  }

  deleteUser(): void {
    this.progressBarService.setProgressBarVisibility(true);
    this.subscribers.push(this.userService.deleteUser({aws_cognito_id: this.users[this.selectedIndex].aws_cognito_id}).subscribe(res => {
      if(res.success) {
        this.getUsers();
        this.messageHandlerService.generateToastMessage('success', '', 'User deleted Successfully');
      } else if(res) {
        this.messageHandlerService.generateToastMessage('error','Communication error:  ', res?.errorMessage);
      }
      this.progressBarService.setProgressBarVisibility(false);
    }));
  }

  createUser() {
    let newUser = this.newUserForm.value;
    newUser.createdBy = this.currentUser.aws_cognito_id;
    newUser.agency_roles = newUser.agency_roles.map(item => {
      return { ...item, agency: {
          agencyID: item?.agency,
          agencyName: this.agencies.find(agency => agency.value == item?.agency)?.label
        }
      }
    });

    let defaultAgencyIndex = -1;
    if(newUser.agency_roles && newUser.agency_roles.length && !newUser.is_super_admin)
      defaultAgencyIndex = newUser.agency_roles.findIndex(agency => agency.defaultAgency);

    if(defaultAgencyIndex != -1) {
      newUser.defaultRole = newUser.agency_roles[defaultAgencyIndex].roles[0];
    }

    this.subscribers.push(this.userService.createUser(newUser).subscribe(res => {
      if(res.success) {
        this.getUsers();

        (this.newUserForm.get('agency_roles') as FormArray).controls.forEach(control => {
          const agency = this.agencies?.find(item => item.value === control.value.agency?.value);
          control.get('agency').patchValue({agencyName: agency?.label, agencyID: agency?.value});
        })

        this.getAgencies();
        this.onSlideBarClose();
        this.showUserCreationSuccessPopup = true;
      } else {
        this.showUserCreationSuccessPopup = false;
        this.messageHandlerService.generateToastMessage('error', '',  res.errorMessage);
      }
    }));
  }

  checkIfEmailExists() {
    this.subscribers.push(this.userService.findUserByEmail({email: this.newUserForm.value.email}).subscribe(res => {
      if(res.success && res.content.data.length && res.content.data[0]?.deleted) {
        this.messageHandlerService.generateToastMessage('error', '', USER_ACCOUNT_ALREADY_EXIST);
      } else if(res.success && res.content.data.length) {
        this.messageHandlerService.generateToastMessage('error', '', EMAIL_ALREADY_REGISTERED_USER);
      } else if(res.success && !res.content.data.length) {
        this.createUser();
      } else {
        this.messageHandlerService.generateToastMessage('error', '', res.errorMessage);
      }
    }));
  }

  async onSelect(event) {
    const file = event.files[0];

    const formData = new FormData();
    formData.append('file', file);

    if (event.currentFiles && event.currentFiles.length > 0) {
      this.progressBarService.setProgressBarVisibility(true);
      await this.userService.uploadFile(formData).subscribe(res => {
        if(res?.message?.Location) {
          this.currentImage = res;
          this.newUserForm.get('picture').setValue(res.message.Location);
          this.fileUpload.clear();
        }
        this.progressBarService.setProgressBarVisibility(false);
      });
    }
  }

  async deleteFile() {
    await this.userService.deleteFile({objectKey: this.newUserForm.value.picture}).subscribe(res => {
      if(res) {
        this.currentImage = null;
        this.fileUpload.clear();
        this.newUserForm.patchValue({picture: null});
      }
    });
  }

  // bulkUpload(event) {
  //   this.progressBarService.setProgressBarVisibility(true);
  //   const file = event.files[0];
  //
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   this.userService.uploadFile(formData).subscribe(res => {
  //     this.progressBarService.setProgressBarVisibility(false);
  //   });
  // }

  onSlideBarClose(){
    this.display = false;
    this.emailType = '';
    this.showEditForm = false;
    this.disableMultiAgencyFilter = true;
    (this.newUserForm.get('agency_roles') as FormArray).clear();
    this.newUserForm.reset();
  }

  // onSelectUserFilter() {
  //   this.pageNo = 0;
  //   this.getUsers();
  //   this.paginator.changePage(this.pageNo)
  // }

  updateUser() {
    if (this.users[this.selectedIndex].aws_cognito_id == this.currentUser.aws_cognito_id && !this.currentUser.is_super_admin) {
      this.messageHandlerService.generateToastMessage('error','', 'Cannot update logged in user');
      return;
    }

    const user = { ...this.users[this.selectedIndex], ...this.newUserForm.value};

    user.agency_roles = user.agency_roles.map(item => {
      return { ...item, agency: {
          agencyID: item?.agency,
          agencyName: this.agencies.find(agency => agency.value == item?.agency)?.label
        }
      }
    });

    if(user.agencyObject) {
      delete user.agencyObject;
    }


    let defaultAgencyIndex = -1;
    if(user.agency_roles && user.agency_roles.length && !user.is_super_admin)
      defaultAgencyIndex = user.agency_roles.findIndex(agency => agency.defaultAgency);

    if(defaultAgencyIndex != -1) {
      user.defaultRole = user.agency_roles[defaultAgencyIndex].roles[0];
    }

    this.subscribers.push(this.userService.updateUser(user).subscribe(res => {
      if(res.success) {
        this.getUsers();
        this.onSlideBarClose();
        this.messageHandlerService.generateToastMessage('success', '', 'User Updated Successfully');
      } else {
        this.messageHandlerService.generateToastMessage('error', '', res?.errorMessage);
      }
    }))
  }


  paginate(event)
  {
    if(!this.isChangingPage)
    {
      this.pageNo = event.page;
      this.noOfUsersPerRow = event.rows;
      this.getUsers();
    }
  }

  changePage()
  {
    this.isChangingPage = true;
    setTimeout(() => {
      this.paginator.changePage(this.pageNo)
      this.isChangingPage = false;
    })
  }


  getAgencies() {
    this.agencyService.getAgencies().subscribe(res => {
      if (res.success) {
        const agencies = res.content.data;
        const allOption = { label: "All", value: "" };
        const mappedAgencies = agencies.map(({ name, id }) => ({ label: name, value: id }));
        this.filteredAgencies = [allOption, ...mappedAgencies];
      }
    })
  }

  onChangeUserStatusFilter(event) {
    this.userStatusFilter = event.value;
    this.pageNo = 0;
    this.getUsers();
    this.paginator.changePage(this.pageNo)
    this.utilService.setDataInLocalStorage(this.componentLocalStorageKey, 'selectedUserStatus', this.selectedUserStatus);
  }

  adminRoles() {
    return adminRoles.includes(this.currentUser.currentUserRole);
  }

  sendResetPasswordEmail() {
    const email = this.newUserForm.value.email
     this.userService.resetPassword(email).subscribe((response)=>{
      if(response.success){
        const code = response.content.data.code;
        if(code === NEW_PASSWORD_REQUEST_SEND){
          this.messageHandlerService.generateToastMessage("success",'',TEMP_PASSWORD_EXPIRED);
        } else {
          this.utilService.sendResetPasswordEmail(email)
        }
      } else {
       this.messageHandlerService.generateToastMessage("error",'','Error in the API call.' + response.errorMessage);

      }
    },(error)=>{
      this.messageHandlerService.generateToastMessage(error,'','Error while fetching data. ' + error.message);
    })
  }

  isRowVisible(rowName: string) {
    if (this.selectedRows && this.selectedRows.length)
      return this.selectedRows.find(selectedRow => selectedRow === rowName);
  }

  onSelectedRowsChange() {
    let selectedIOH = { selectedIOHUserRows: [] };

    if(this.localStorageService.get('userSettings' + this.currentUser?.aws_cognito_id))
      selectedIOH = JSON.parse(this.localStorageService.get('userSettings' + this.currentUser?.aws_cognito_id));

    selectedIOH.selectedIOHUserRows = this.selectedRows
    this.localStorageService.set('userSettings' + this.currentUser?.aws_cognito_id, JSON.stringify(selectedIOH));
  }

  isPhoneAndFaxNumberValid() {
    return this.utilService.isPhoneAndFaxNumberValid(this.newUserForm.value);
  }

  async checkSelectedAgencyRoles(selectedIndex?){
     const formData = await selectedIndex ? this.users[selectedIndex] : this.newUserForm.value;
    if (!formData) {
      return;
    }
    const isMultiAgencyEnabled = isMultiAgenciesUserEnabled(formData?.agency_roles);
    if (isMultiAgencyEnabled) {
      this.disableMultiAgencyFilter = false;
      this.newUserForm.get('userSettings').get('isMultiAgenciesAllowed').patchValue(formData?.userSettings?.isMultiAgenciesAllowed);
    } else {
      this.disableMultiAgencyFilter = true;
      this.newUserForm.get('userSettings').get('isMultiAgenciesAllowed').patchValue(false);
    }
  }

  //   bulkUploadTemplateClick() {
  //   const a = document.createElement('a');
  //   a.href = bulkUploadTemplate;
  //   a.download = 'bulkUploadTemplate';
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //
  //   this.dropdown.writeValue(this.dropdown.value === 'Download Template' ? null : 'Download Template');
  // }

  bulkUpload(event) {
    this.progressBarService.setProgressBarVisibility(true);
    const file = event.files[0];
    if(file.type !== BULK_USER_UPLOAD_ALLOWED_FORMAT){
      this.bulkUploadUsers.clear();
      this.progressBarService.setProgressBarVisibility(false);
      this.messageHandlerService.generateToastMessage('error','', BULK_USER_UPLOAD_FILE_FORMAT_ERROR);
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    this.userService.bulkUpload(formData).subscribe(res => {
      if(res && res.success){
        this.progressBarService.setProgressBarVisibility(false);
        this.getUsers();
        this.messageHandlerService.generateToastMessage('success','', BULK_USER_UPLOAD);
        this.bulkUploadUsers.clear();
      }else{
        this.bulkUploadUsers.clear();
        let errorMessage;
        if(res && res?.message && res?.message?.length > 0){
          errorMessage = res?.message || res.errorMessage;
        }else{
          errorMessage = res;
        }

        this.progressBarService.setProgressBarVisibility(false);
        this.messageHandlerService.generateToastMessage('error','', BULK_USER_UPLOAD_FAILED_MESSAGE);
      }
    });
  }


}
